<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>
                <span id="slash">/</span>
                <span id="page-now">Araştırmalar</span>
                
            </div>
            <div id="header-text">
                <h1>Araştırmalarımız</h1>
            </div>
        </div>

    </div>
</div>

<section id="researchs">
  <div class="blog-card">
    <div class="meta">
      <div class="photo" style="background-image: url('../../../assets/Img/maras-depremi.webp')"></div>
      <ul class="details">

        <li class="date">2022 Aralık</li>
      
      </ul>
    </div>
    <div class="description">
      <h1>Sivil Toplumda Afet Hazırlığı</h1>
      <!-- <h2>Opening a door to the future</h2> -->
      <p> İstanbul Sosyal Girişim Derneği olarak Afet ve acil durumlar için sivil toplum kapasitesini güçlendirmeyi hedefleyen modellerin geliştirildiği araştırma projesini inceleyin.
          
      </p>
      <p class="read-more">
        <a routerLink="/arastirmalar/Afet-Hazirligi">Devamını Oku  </a>
      </p>
    </div>
  </div>
  <div class="blog-card">
        <div class="meta">
          <div class="photo" style="background-image: url('https://res.cloudinary.com/dio02djlz/image/upload/v1649242668/absolutvision-bSlHKWxxXak-unsplash_yfnzzr.webp')"></div>
          <ul class="details">
 
            <li class="date">2022 Nisan</li>
          
          </ul>
        </div>
        <div class="description">
          <h1>Türkiye Basınında Arap İmajı</h1>
          <!-- <h2>Opening a door to the future</h2> -->
          <p> İstanbul Sosyal Girişim Derneği tarafından gerçekleştirilen ve Türk Arap Medya Derneğinin de katkılarıyla hazırlanan “Türkiye Medyasında Arap İmajı-Nisan 2022” başlıklı araştırma raporunu inceleyin.
              
          </p>
          <p class="read-more">
            <a routerLink="/arastirmalar/Turkiye-Basininda-Arap-Imaji">Devamını Oku  </a>
          </p>
        </div>
      </div>
     
</section>