import { Observable } from 'rxjs';
import { ListResponseModule } from './../models/listResponseModule';
import { News } from './../models/news';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../models/globalConstants';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
 
  apiUrl:string = GlobalConstants.apiUrl;    
  constructor(private httpClient:HttpClient) { }

 

  getNews():Observable<ListResponseModule<News>> {
    let newPath=this.apiUrl+"news/getall";
   return this.httpClient.get<ListResponseModule<News>>(newPath);
  }
}
