<footer>
    <div class="container">
        <div class="row">
            <div class="col-md-4 footer-hakkimizda">
                <div class="header">
                    <h4>Hakkımızda</h4>
                    <span></span>
                </div>
                <div class="body">
                    <p>
                        {{aboutUsBodyText}}...
                    </p>
                </div>
                <div class="body">
                    <ul>
                        <li>
                            <a href="https://www.instagram.com/istsocialenterprise/" target="_blank"> <i class="fab fa-instagram fa-2x" title="Instagram"></i></a>
                        </li>
                        <li>
                            <a href="" target="_blank"> <i class="fab fa-twitter fa-2x" title="twitter"></i></a>
                        </li>
                        <li>
                            <a  href="https://www.linkedin.com" target="_blank"> <i class="fab fa-linkedin fa-2x" title="linkedin"></i></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com" target="_blank"> <i class="fab fa-facebook fa-2x" title="facebook"></i></a>
                        </li>
                    </ul>
                </div>





            </div>

            <div class="col-md-2 footer-sayfalar">
                <div class="header">
                    <h4>Hızlı Linkler</h4>
                    <span></span>
                </div>

                <div class="body">
                    <ul>
                        <li><a routerLink=""><i class="fas fa-chevron-right"></i> &nbsp; Ana Sayfa </a></li>
                        <li><a routerLink="hakkimizda"><i class="fas fa-chevron-right"></i> &nbsp; Hakkımızda </a></li>
                        <li><a routerLink="bakisacimiz"><i class="fas fa-chevron-right"></i> &nbsp; Bakış Açımız</a></li>
                        <li><a routerLink="ongorumuz"><i class="fas fa-chevron-right"></i> &nbsp; Öngörümüz</a></li>
                        <li><a routerLink="hedefimiz"><i class="fas fa-chevron-right"></i> &nbsp; Hedefimiz</a></li>
                        <li><a routerLink="tuzuk"><i class="fas fa-chevron-right"></i> &nbsp; Tüzük</a></li>
                        <li><a routerLink="haberler"><i class="fas fa-chevron-right"></i> &nbsp; Haberler</a></li>
                        <li><a routerLink="cozumOrtaklari"><i class="fas fa-chevron-right"></i> &nbsp; Çözüm Ortakları</a></li>
                        <li><a routerLink="yonetimKurulu"><i class="fas fa-chevron-right"></i> &nbsp; Yönetim Kurulu</a></li>
                        <li><a routerLink="iletisim"><i class="fas fa-chevron-right"></i> &nbsp; İletişim</a></li>
                    </ul>
                </div>

                
            </div>
            <div class="col-md-3 footer-iletisim">
                <div class="header">
                    <h4>İletişim</h4>
                    <span></span>
                </div>
                <div class="body">
                    <ul>
                        <li><i class="fas fa-map-marker-alt"></i>&nbsp; Maltepe Mah. Eski Çırpıcı Yolu Sk. Parima No: 8 İç Kapı No: 10 PK: 34010 Zeytinburnu - İSTANBUL</li>
                        <li><i class="fas fa-phone-alt fa-1.25x"></i> &nbsp; +90 0212 890 00 18 </li>
                        <li><i class="fas fa-fax"></i>&nbsp; +90 0212 890 00 35 </li>
                        <li><i class="fas fa-envelope"></i>&nbsp;info@istanbulsosyalgirisim.org</li>
                    </ul>
                </div>
                <div class="header">
                    <h4>Bilgi Servisi</h4>
                    <span></span>
                </div>
                <div class="body">
                    <ul>
                        <li><a routerLink="aydinlatma-metni" style="color: #fff; text-decoration: none;"><i class="fas fa-chevron-right"></i> &nbsp; KVK Aydınlatma Metni </a></li>
                        
                    </ul>
                </div>
            </div>
            <div class="col-md-3 footer-map">
                <div class="header">
                    <h4>Harita</h4>
                    <span></span>
                </div>
                <div class="body">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.555823809896!2d28.900290715013565!3d41.0130941793003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cabb01a44cd997%3A0x6264aa230e88ea30!2sParima%20Ofis!5e0!3m2!1str!2str!4v1621546853783!5m2!1str!2str"
                        width="300" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
        </div>


    </div>
</footer>
<div id="footer-copyright">
    <div class="container">
        <p>© Telif Hakları 2020 - 2022
            <script>
                document.write(new Date().getFullYear());
            </script>
            | <span><strong>İstanbul Sosyal Girişim</strong> </span> | Tüm Hakları Saklıdır.</p>

    </div>
    <div id="desinger">
        Software : &nbsp;<a href="https://orientedyazilim.com/" target="_blank">Oriented Yazılım</a>
    </div>
</div>