<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink="haberler"> Haberler</a></span>
                <span id="slash">/</span>
                <span id="page-now">Haberler</span>
            </div>
            <div id="header-text">
                <h1>Eğitimde Dijitalleşme ve 21. Yüzyıl Becerileri Söyleşisi
                </h1>
            </div>
        </div>

    </div>

</div>




<div id="about">
    <div class="container">


        <div class="content">

            <div class="row">
                <div class="col-md-7">
                    <h3 class="header">Ardahan Üniversitesi'nde “Eğitimde Dijitalleşme ve 21. Yüzyıl Becerileri
                        Söyleşisi” düzenlendi
                    </h3>
                    <p class="text">Ardahan Üniversitesi'nde “Eğitimde Dijitalleşme ve 21. Yüzyıl Becerileri Söyleşisi”
                        düzenlendi

                    </p>
                    <p class="text">İstanbul Sosyal Girişim koordinatörlüğünde ve Ardahan İl Milli Eğitim Müdürlüğü’nün
                        katklarıyla hazırlanan “Eğitimde Dijitalleşme ve 21. Yüzyıl Becerileri Söyleşisi” Ardahan
                        Üniversitesi'nde düzenlendi.



                    </p>
                    <p class="text">Dr. Gökhan Kavak'ın moderatörlüğünde gerçekleştirilen söyleşide Ardahan Milletvekili
                        Prof. Dr. Orhan Atalay, Ardahan Üniversitesi Rektörü Prof. Dr. Mehmet Biber, İstanbul Sosyal
                        Girişim Başkanı Prof. Dr. Metin Toprak ve Ardahan İl Milli Eğitim Müdürü Erhan Bulut konuşma
                        yaptı.
                    </p>
                </div>
                <div class="col-md-5"> <img
                        src="https://res.cloudinary.com/dio02djlz/image/upload/v1655816864/soylesi-_2__zux8c4.webp"
                        alt="">
                </div>
            </div>
            <br>
        


            <p class="text">Söyleşinin açış konuşmasını gerçekleştiren Rektör Biber, Ardahan Üniversitesi’nin pandemi
                sürecinde uzaktan eğitime en hızlı uyum sağlayan üniversitelerden biri olduğunu belirterek bu süreçte
                birçok uygulamanın hayata geçirildiğini ifade etti.
            </p>
            <p class="text">Milletvekili Prof. Dr. Atalay ise Ardahan'da gerçekleştirilen eğitim alt yapısına ilişkin
                bilgiler verdi.
            </p>


            <p class="text">Dijital dönüşüme ilişkin konuşan Prof. Dr. Atalay, metaverse (kurgusal evren) projesi ve
                blockchain (blok zinciri) teknolojisinin hayatın her alanında kullanılmaya başlandığını belirtti.
            </p>
            <p class="text">Prof. Dr. Toprak da dijitalleşme sürecini uluslararası perspektif ve Türkiye perspektifi
                üzerinden değerlendirdi.
            </p>
            <p class="text">Dijitalleşmenin döngüsel bir süreç olduğunu ifade eden Toprak, süreci dört aşamada
                tanımladı. Bu süreçleri; dijitizasyon, dijitalizasyon, dijital dönüşüm ve dijital hazır-bulunuşluk
                olarak sıralayan Profesör Toprak, değişim ve dönüşümlerin toplum üzerindeki mevcut ve gelecekteki olası
                etkileri üzerine konuştu.
            </p>
            <p class="text">Son olarak Endüstri 4.0 ve 5.0 yaklaşımlarına ilişkin bilgi veren Toprak, dijital okul ve
                dijital kampüs kavramlarını tanımladı. Soru-cevap bölümüyle devam eden söyleşi, hatıra fotoğrafı
                çekilmesinin ardından sona erdi.
            </p>
            <p class="text">Milli Eğitim Müdürü Bulut ise dijitalleşmenin avantaj ve dezavantajlarına değindi.
            </p>
            <p class="text">Söyleşiye; okul müdürleri ve öğretmenler, il protokolü, üniversite akademik ve idari
                personeli katıldı.

            </p>
            <img src="https://res.cloudinary.com/dio02djlz/image/upload/v1655816864/soylesi-_3__iercpq.webp"
            alt="">



        </div>



    </div>
</div>