

<div class="home-loader" *ngIf="dataLoaded == false">
    <div class="spinner"></div>
  </div>


<section id="home">
    <div id="carouselExampleIndicators" class="carousel slide carousel-fade " data-ride="carousel" data-interval="8000">
        <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
           

      
          
             
            <div class="carousel-item"  [ngClass]="{'active': homes[0] === home}"   *ngFor="let home of homes;">
                <div class="bg-color" style="background-image: url('{{home.imgUrl}}');">

                </div>
                <div class="content">
                    <div class="text">
                        <h1 class="header-text">{{home.headerText}}</h1>

                        <p class="body-text">{{home.bodyText}}
                        </p>
                        <div class="buttons">
                            <div class="button-active" routerLink="hakkimizda">Bizi Tanı</div>
                            <div class="button-passive" routerLink="iletisim">İletişime Geç</div>
                        </div>
                    </div>
                </div>
            </div>  

        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"  data-slide="prev">
            <div class="next-buttons">

                <span aria-hidden="true">
                    <img src="../../../assets/Icons/left.png" alt="">
                </span>
                <span class="sr-only">Previous</span>
            </div>

        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"  data-slide="next">
            <div class="next-buttons">
                <span aria-hidden="true">
                    <img src="../../../assets/Icons/right.png" alt="">
                </span>
                <span class="sr-only">Next</span>
            </div>

        </a>
    </div>

</section>



<section id="hakkimizda">


    <div class="img-box">
        <div class="effect-box">
        </div>
    </div>
    <div class="content-box">

        <div class="header">
            <h2>{{headerText}}</h2>
        </div>
        <div class="hakkimizda-line"></div>
        <div class="body">
            <p> {{aboutUsText}} </p>
                <h6 class="about-signature">Metin Toprak <br>
                    İstanbul Sosyal Girişim Başkanı
                    </h6>
        </div>
        <a routerLink="/hakkimizda" style="text-decoration: none;">
            <div id="button">Devamı &nbsp; &nbsp; <i class="fas fa-arrow-right"></i> </div>
        </a>
    </div>


</section>





<div id="mission-vision" class="">



    <hr class="line">
    <div class="content-base container">


        <div class="content">
            <div class="circle-box">
                <i class="fas fa-book"></i>
            </div>
            <div class="body">
                <h2 class="header-text">{{ourPointOfViewHeader}}</h2>
                <p class="body-text">{{ourPointOfViewText}}...</p>
            </div>
        </div>
        <div class="content">
            <div class="circle-box">
                <i class="fas fa-feather-alt"></i>
            </div>
            <div class="body">
                <h2 class="header-text">{{ourGoalHeader}}</h2>
                <p class="body-text">{{ourGoalText}}...</p>
            </div>

        </div>
        <div class="content">
            <div class="circle-box">
                <i class="fab fa-pagelines"></i>
            </div>
            <div class="body">
                <h2 class="header-text">{{ourPredictionHeader}}</h2>
                <p class="body-text">{{ourPredictionText}}...</p>
            </div>
        </div>
    </div>

    <a routerLink="/ongorumuz" id="button">
        Devamı &nbsp; &nbsp; <i class="fas fa-arrow-right"></i>
    </a>


</div>


 




<section id="cozum-ortaklari">
   
    <h2>Çözüm Ortakları</h2>
    <div class="container">

        <div class="solution-partner" *ngFor="let solutionPartners of solutionPartners"><a href="{{solutionPartners.webAddress}}"><img src="{{solutionPartners.companyImgUrl}}"></a></div>
    
    </div>  
    <div class="button-box">
        <div id="button" routerLink="cozumOrtaklari">
            Görüntüle
        </div>
    </div>
 
   
</section> 

 

<section id="haberler">

    <div class="header">
        <h2>Haberler & Duyurular</h2>
    </div>

    <div class="container">
        <div class="box-1 box">

            <div class="content">
                <span class="baslik">Önemli Gelişme</span>

            </div>


        </div>
        <div class="box-2 box">
            <div class="content">
                <span class="baslik">Haberler</span>
            </div>
        </div>
        <div class="box-3 box">
            <div class="content">
                <span class="baslik">Duyurular</span>
            </div>
        </div>
    </div>




    <div class="button-box">
        <div id="button" routerLink="haberler">
            Tümünü Görüntüle
        </div>
    </div>

</section>

<section id="social-media">
    <div class="social-media-header">
        <h2>BİZİ TAKİP EDİN</h2>
    </div>
    <div class="social-media-box">

        <div class="social-overlap process-scetion mt100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="social-bar">
                            <div class="social-icons mb-3 iconpad text-center">

                                <a href="" target="_blank" class="slider-nav-item"><i class="fab fa-facebook"></i></a>
                                <!-- <a href="" target="_blank" class="slider-nav-item"><i class="fab fa-google-plus"></i></a> -->
                                <a href="" target="_blank" class="slider-nav-item"><i class="fab fa-twitter"></i></a>
                                <a href="https://www.instagram.com/istsocialenterprise/" target="_blank" class="slider-nav-item"><i class="fab fa-instagram"></i></a>
                                <a href="https://www.linkedin.com/company/istanbul-social-enterprise/about/" target="_blank" class="slider-nav-item"><i class="fab fa-linkedin"></i></a>
                                <!-- <a href="" target="_blank" class="slider-nav-item"><i class="fab fa-pinterest"></i></a>
                                <a href="" target="_blank" class="slider-nav-item"><i class="fab fa-skype"></i></a>
                                <a href="" class="slider-nav-item"><i class="fab fa-youtube"></i></a>
                                <a href="" class="behance slider-nav-item"><i class="fab fa-behance"></i></a>
                                <a href="" target="_blank" class="slider-nav-item"><i class="fab fa-dribbble"></i></a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</section>