import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-regulations',
  templateUrl: './regulations.component.html',
  styleUrls: ['./regulations.component.css']
})
export class RegulationsComponent implements OnInit {

  constructor(private titleService:Title) {
  }

 ngOnInit(): void {
this.titleService.setTitle("İSG | Tüzük");
 }

}
