import { BoardOfDirectorDetail } from './../../models/boardOfDirectorDetail';
import { BoardOfDirectorService } from './../../services/board-of-director.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  dataLoaded:boolean=false;

  boardOfDirectors:BoardOfDirectorDetail[]=[];
  constructor(
    private titleService:Title,
    private boardOfDirectorService:BoardOfDirectorService
    ){}

 ngOnInit(): void {
 this.titleService.setTitle("İSG | Yönetim Kurulu");
 this.getAll();
 }

 getAll(){
   this.boardOfDirectorService.getBoardOfDirectorDetails().subscribe((response)=>{

    this.boardOfDirectors=response.data;
    this.dataLoaded = true;
   },(errorResponse)=>{

   })
 }


 
}
