import { SoylesiComponent } from './Components/soylesi/soylesi.component';
import { ArabImageInTurkisPressComponent } from './Components/researchsComponents/arab-image-in-turkis-press/arab-image-in-turkis-press.component';
import { ResearchsComponent } from './Components/researchs/researchs.component';
import { IlluminationTextComponent } from './Components/illumination-text/illumination-text.component';
import { OurPredictionComponent } from './Components/our-prediction/our-prediction.component';
import { OurGoalComponent } from './Components/our-goal/our-goal.component';
import { OurPointOfViewComponent } from './Components/our-point-of-view/our-point-of-view.component';
import { ErrorPageComponent } from './Components/error-page/error-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './Components/about/about.component';
import { ContactComponent } from './Components/contact/contact.component';
import { HomeComponent } from './Components/home/home.component';
import { MissionVisionComponent } from './Components/mission-vision/mission-vision.component';
import { NewsComponent } from './Components/news/news.component';
import { OurValuesComponent } from './Components/our-values/our-values.component';
import { RegulationsComponent } from './Components/regulations/regulations.component';
import { SolutionPartnersComponent } from './Components/solution-partners/solution-partners.component';
import { TeamComponent } from './Components/team/team.component';
import { EarthquakePageComponent } from './Components/researchsComponents/earthquake-page/earthquake-page.component';

const routes: Routes = [
  
    {path:"" ,pathMatch:"full", component:HomeComponent},
    {path:"hakkimizda" , component:AboutComponent},
    {path:"yonetimKurulu" , component:TeamComponent},
    {path:"tuzuk" , component:RegulationsComponent},
    {path:"arastirmalar/Turkiye-Basininda-Arap-Imaji" , component:ArabImageInTurkisPressComponent},
    {path:"arastirmalar/Afet-Hazirligi" , component:EarthquakePageComponent},
   {path:"aydinlatma-metni" , component:IlluminationTextComponent}, 
   {path:"degerlerimiz" , component:OurValuesComponent},
    {path:"haberler" , component:NewsComponent},
    {path:"arastirmalar" , component:ResearchsComponent},
    {path:"cozumOrtaklari" , component:SolutionPartnersComponent},
    {path:"iletisim" , component:ContactComponent},
    {path:"bakisacimiz" , component:OurPointOfViewComponent},
    {path:"hedefimiz" , component:OurGoalComponent},
    {path:"ongorumuz" , component:OurPredictionComponent},

    {path:"soylesi" , component:SoylesiComponent},

    {path: '**', component:ErrorPageComponent },
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
  
})
export class AppRoutingModule { }
