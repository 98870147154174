import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-earthquake-page',
  templateUrl: './earthquake-page.component.html',
  styleUrls: ['./earthquake-page.component.css']
})
export class EarthquakePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
