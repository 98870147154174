import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AboutUs } from '../models/aboutUs';
import { GlobalConstants } from '../models/globalConstants';
import { ListResponseModule } from '../models/listResponseModule';

@Injectable({
  providedIn: 'root'
})
export class AboutUsService {
 
  apiUrl:string = GlobalConstants.apiUrl;    

  constructor(private httpClient: HttpClient) { }

 

   
  getAboutUs():Observable<ListResponseModule<AboutUs>> {
    let newPath=this.apiUrl+"aboutUs/getall";
   return this.httpClient.get<ListResponseModule<AboutUs>>(newPath);
  }

}
