<!-- <div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <h3>Oops! Aradığınız Sayfa Bulunamadı</h3>
            <h1><span>4</span><span>0</span><span>4</span></h1>
        </div>
        <h2>we are sorry, but the page you requested was not found</h2>
    </div>
</div> -->


<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <h1>404</h1>
        </div>
        <h2>ÜZGÜNÜZ, SAYFA BULUNAMADI!</h2>
        <p>ARADIĞINIZ SAYFANIN ADI DEĞİŞMİŞ VEYA KALDIRILMIŞ OLABİLİR.</p>
        <a routerLink="">ANA SAYFA'YA  DÖN</a>
    </div>
</div>