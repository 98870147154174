import { SolutionPartnerService } from './../../services/solution-partner.service';
import { SolutionPartner } from './../../models/SolutionPartner';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-solution-partners',
  templateUrl: './solution-partners.component.html',
  styleUrls: ['./solution-partners.component.css']
})
export class SolutionPartnersComponent implements OnInit {

  solutionPartners : SolutionPartner[] = [];
  dataLoaded:boolean=false;
  
  constructor(
    private titleService:Title,
    private solutionPartnerService:SolutionPartnerService
    ){}

 ngOnInit(): void {
  this.titleService.setTitle("İSG | Çözüm Ortakları");  
  this.getSolutionPartner();
 }


 getSolutionPartner(){
  this.solutionPartnerService.getSolutionPartner().subscribe((response) => {
    this.solutionPartners = response.data;
    this.dataLoaded = true;
  });
 }

}
