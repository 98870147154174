import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BoardOfDirectorDetail } from '../models/boardOfDirectorDetail';
import { GlobalConstants } from '../models/globalConstants';
import { ListResponseModule } from '../models/listResponseModule';
import { ResponseModule } from '../models/responseModule';
import { SingleResponseModule } from '../models/singleResponseModule';

@Injectable({
  providedIn: 'root'
})
export class BoardOfDirectorService {


   
  apiUrl:string = GlobalConstants.apiUrl;    
  constructor(private httpClient:HttpClient) { }
 



   //Detail ---------------------------------------------------

   getBoardOfDirectorDetails():Observable<ListResponseModule<BoardOfDirectorDetail>> {
    let newPath=this.apiUrl+"boardOfDirectors/getalldetail";
   return this.httpClient.get<ListResponseModule<BoardOfDirectorDetail>>(newPath);
  }


 

}
