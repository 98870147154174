<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>
                <span id="slash">/</span>
                <span id="page-now">Araştırmalar</span>
                <span id="slash">/</span>
                <span id="page-now">Türkiye Basınında Arap İmajı</span>

            </div>
            <div id="header-text">
                <h1>Türkiye Basınında Arap İmajı
                </h1>
            </div>
        </div>

    </div>
</div>

<div id="content">
    <div class="card">

        <div class="card-title">Türkiye Basınında Arap İmajı</div>

        <p>İstanbul Sosyal Girişim Derneği tarafından gerçekleştirilen ve Türk Arap Medya Derneğinin de katkılarıyla
            hazırlanan “Türkiye Medyasında Arap İmajı-Nisan 2022” başlıklı araştırma raporu yayımlandı.</p>

        <p>Türkiye’de faaliyet gösteren Arap medya mecraları da dikkate alınarak gerçekleştirilen araştırma, Türk ve
            Arap medya mensuplarının değerlendirme ve tespitleri ile beklenti ve önerilerini ortaya koymaktadır.
            Araştırmada, genel olarak Arap algısı ve Türkiye’deki Suriyeli sığınmacılara ilişkin algının tespiti
            amaçlanmıştır. Araştırmaya Türk ve Arap medya mensupları katılmıştır.</p>
        <p>Araştırmanın alan uygulaması, 19-27 Mart 2022 tarihleri arasında gerçekleştirilmiştir. Araştırmaya, 18 yaş
            üzeri 804 Türk ve Arap medya çalışanı katılmış ve CASI (Bilgisayar Destekli Kendi Kendine Görüşme)
            tekniği kullanılmıştır. </p>
        <br>
        <p>Araştırma üç bölümden oluşmaktadır:
        </p>
        <div>
            <ul>
                <li>Görüşülen kişilerin demografik özellikleri</li>
                <li>Türk ve Arap medyasına yönelik kanaatler</li>
                <li>Suriyeli sığınmacılara yönelik kanaatler</li>
            </ul>
        </div>
    </div>

    <div class="card">

        <div class="card-title">Demografik Özellikler</div>
        <p>Araştırmaya katılanların yaklaşık yüzde 21’i kadın, yüzde 65’i 40 yaş altı ve yüzde 78’i üniversite ve üstü
            eğitim almış kişilerden oluşmaktadır. Cinsiyet temsili, eğitim düzeyi ve yaş grupları kategorilerinde
            değerlendirmeye imkan verecek bir örneklem söz konusudur. Örneklem seçiminde, Türkiye’de faaliyet gösteren
            başta gazeteciler olmak üzere diğer medya mecralarında çalışan medya mensupları yaş, cinsiyet, eğitim ve
            menşe ülke itibariyle dikkate alınmış ve yaklaşık 3000 civarındaki yabancı medya mensubunun yüzde 20’si
            kümeli örneklemi oluşturmuştur. Türk medya mensuplarının toplam örneklemdeki oranı yüzde 26 civarındadır.
        </p>
        <p>Örneklemde Suriye menşeli medya mensuplarının ağırlıklı bir sayıdadır. Ancak, analizlerde genel olarak Arap
            ülkeleri kökenli medya mensuplarının değerlendirmeleri arasında istatistiksel olarak anlamlı bir farklılaşma
            dikkat çekmemektedir. Araştırmaya katılanların çok düşük sayıda da olsa Batı Avrupa ülkelerinden Arap
            kökenli gazeteciler de katılmıştır.
        </p>
    </div>

    <div class="card">
        <div class="card-title">Türkiye ve Arap Medyasına Yönelik Kanaatler
        </div>
        <p>Türkiye’deki Arap imajı, Suriye’den gelen sığınmacı ve göçmenler nedeniyle Suriyeli imajı ile önemli bir
            paralellik göstermektedir. Türk Hükümeti, sığınmacı ve göçmen kavramlarını, uluslararası anlaşmaların
            getirdiği çeşitli yükümlülükleri aşmak nedeniyle kullanmaktan kaçınmakta, bunun yerine misafir terimini
            tercih etmektedir. Ancak birçok resmi yetkili, resmi yazılı açıklamaların dışında, günlük konuşma ve
            beyanlarında sığınmacı, mülteci ve misafir kavramlarını sıklıkla birbirinin yerine geçecek şekilde
            kullanmaktadır.
        </p>

        <p>Türkiye’de faaliyet gösteren medya mecralarında Araplara ve özellikle Suriyelilere yönelik haberlerin doğal
            olarak yakinen takip edildiği görülmektedir.
        </p>
        <p>Araştırmaya katılanların önemli bir kısmı (%78) Türk medya mecralarında Suriyelilerle/Araplarla ilgili
            haberlerin, Türk halkının bakışaçısını etkilediğini düşünmektedir. Türk medyasında
            Suriyelilerle/Araplarla ilgili haberlerin objektif ve tarafsız olduğunu düşünenlerin oranı yüzde 26, taraflı
            ve sübjektif olduğunu düşünenler ise yüzde 41 civarındadır. Dolayısıyla, Türk medyasının Arap/Suriyelilere
            yönelik taraflı bir politika izlediği kanaati güçlüdür.
        </p>
        <p>Araştırma katılımcılarının yarıdan fazlası, Arap dünyası ile ilgili haberleri sosyal medya ve internetten
            takip etmektedir. Arap dünyası ile ilgili haberleri, katılımcıların %53’ü sosyal medyadan, yüzde 38’i TV’den
            yüzde 37’si internet haberlerinden, yüzde 19’u gazete ve dergi gibi süreli basılı yayınlardan, yüzde 10’u
            Araplara ait medya kuruluşlarının internet sitelerinden ve yüzde 5’i de radyodan takip ettiğini
            belirtmektedir. Birden fazla mecradan haber takibi söz konusu olduğu için her bir cevabı bağımsız
            değerlendirmek gerekmektedir. Araplara ilişkin haber mecralarına güvende sosyal medya ve internet
            haberciliğinin diğerlerine göre nispeten daha objektif olduğu kanısı söz konusudur.
        </p>
        <p>
            Arap dünyası ile ilgili haberleri takipte, Türk ve yabancı medya mecralarında Türk TV kanalları ve sosyal
            medya mecraları öne çıkmaktadır.

        </p>
        <p>TRT kanalları, Al Jazeera, Anadolu Ajansı, Hükümete destekleyen gazete ve TV’ler Araplara yönelik en olumlu
            haber ve yorumların yayımlandığı mecralar olarak görülmektedir.
        </p>
        <p>Araştırma katılımcılarına göre, Türkiye’de faaliyet gösteren medya mecralarında Araplara yönelik en olumsuz
            yayınları ise sırasıyla AK Parti Hükümetine muhalif olan medya mecraları, Fox TV, Halk TV, Sözcü, Cumhuriyet
            Gazetesi ve SKY News Arabic yapmaktadır.
        </p>
        <p>Katılımcıların yaklaşık yarısına göre, Arap dünyasından Türkiye’ye yönelik en olumlu haberleri Katar, en
            olumsuz haberleri ise Birleşik Arap Emirlikleri, Suudi Arabistan, Mısır ve Suriye yapmaktadır. Katar’ın
            olumlu haber yapmada yüksek bir imaja sahip olmasında Al Jazeera kanalının bölgesel ve küresel alandaki
            yayın politikasının önemli bir payının olduğu söylenebilir.

            Katılımcılar, Türkiye’nin Arap medyasındaki algısını güçlendirmek için ilk sırada, akran medya kuruluşları
            arasında ortak yayın, program ve altyapı alanlarında işbirlikleri yapılmasını önermektedir. Ülkeler arasında
            ve medya kuruluşları arasında ortaklıkların kurulması, Arap dünyası ve Türkiye’yi hedefleyen TV kanalları ve
            sosyal medya mecralarının kurulması da öneriler arasındadır. STK diplomasisi, medya kuruluşlarının özellikle
            Suriyeli göçmenlerin toplumsal uyum ve kentsel entegrasyonu bakımından bir bileşen olarak kullanılması,
            ülkelerin birbirlerinin iç işlerine saygıyı esas almaları ve müdahaleden kaçınmaları, medya mecralarında
            insan haklarına ve çağdaş değerlerle çelişen haber, yorum ve görsellerin kullanılmaması, nefret dili,
            ötekileştirme ve dışlayıcı milliyetçiliğin kullanılmaması da öne çıkan diğer önerilerdir.

        </p>
    </div>


    <div class="card">
        <div class="card-title">Suriyeli Sığınmacılara Yönelik Kanaatler
        </div>
        <p>Türkiye Hükümetinin Araplarla ilgili dış politikası katılımcıların yaklaşık 82’si tarafından olumlu
            karşılarken, olumsuz karşılayanların oranı %7’dir. Benzer şekilde Türkiye Hükümetinin Suriyeli
            sığınmacılara yönelik politikası da %78 oranında olumlu bulunmaktadır. Suriyelilerin Türkiye’de çalışmaları
            güçlü şekilde desteklenmektedir. Buna göre, Suriyelilere daimi çalışma izni verilmesini savunanlar %66,
            geçici çalışma izni verilmesini savunanlar ise %13 oranındadır. Çalışma iznine karşı olanların oranı ise %8
            civarındadır.
        </p>
        <p>Suriyelilerin Türkiye’ye kabul edilmesini olumlu karşılayanların öne çıkardıkları argümanlar sırasıyla, bunun
            bir insanlık gereği olduğu, Türkiye’nin tarihinden ve coğrafyasından kaynaklanan bir gereklilik olduğu,
            din kardeşliğinin gereği olduğudur.

        </p>
        <p>Suriyelilerin Türkiye’ye kabulüne yönelik yüksek düzeydeki olumlu yaklaşım, Suriyelilerin ülkelerine geri gönderilmesi konusunda farklılaşmakta ve Suriyelilerin geri gönderilmesi gerektiğine ilişkin kanaat %61 oranı gibi yüksek bir düzeydedir. Suriyelilerin tampon bölgelere gönderilmesi, bundan sonra Türkiye’ye kabul edilmemesi, sadece kamplarda barındırılması gibi seçenekler görece yüksek oranlarda olmasa da dile getirilen alternatifler arasındadır. 
        </p>
        <p>Türkiye’deki Suriyelilerin topluma uyum ve çalışma izni almaları konusunda yüksek bir destek söz konusudur. Suriyelilerin geri gönderilmesinin desteklenmesi ile Türkiye’de yerleşmelerinin desteklenmesi ilk bakışta çelişkili gibi görünebilir; ancak, Suriyelilerin geri gönderilme ihtimali olsa bile Türkiye’de kaldıkları sürece uyum ve entegrasyon politikalarına tabi olmaları savunulabilir ve bu iki görüş arasında bir tezat olması gerekmez. Nitekim Suriyelilerin Türkiye’de kalmaları durumunda birçok soruna yol açacağı yine yüksek bir oranda (%70) dile getirilmektedir. Suriyelilerin Türkiye’de çeşitli sorunlara yol açmasının ihtimal dahilinde olduğunu savunmak, Suriyelilere yönelik politika geliştirilmesini savunmaya engel değildir. Suriyelilerin Türk toplumuna uyum sağlayacağını düşünenlerin oranı %71 oranındadır. Dolayısıyla, farklı bir kültür ve ekosistemden gelen milyonlarca insanın uyum ve entegrasyon sorunları olduğunu tespit etmek ile bunlara yönelik politika önerileri geliştirmek arasında bir çelişki aranması gerekmemektedir.</p>
    

        <p>Göç İdaresi Başkanlığı’nın uyum çalışmalarını başarılı bulanların oranı %38, başarısız bulanların oranı ise %32 civarındadır. Türkiye gibi potansiyel olarak sürekli dış göçe muhatap bir ülkenin çok daha güçlü bir kurumsal yapıyla iç ve dış göç olgusuna yaklaşması gerektiği söylenebilir. Nitekim Göç İdaresi Başkanlığı statüsünün genel müdürlük düzeyinden müsteşarlık düzeyine yükseltilmesi, yetersiz olmakla birlikte bu yönde atılmış önemli bir adımdır.


        </p>
    </div>


<div class="card">
    <a href="../../../../assets/pdfs/ISG-TAM-Arap-Algisi.pdf" target="_blank" class="btn btn-success">Araştırma Dosyasını İncele</a>
    
</div>
 
</div>