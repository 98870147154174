import { MissionVisionService } from './../../services/mission-vision.service';
import { MissionVision } from './../../models/missionVision';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-our-values',
  templateUrl: './our-values.component.html',
  styleUrls: ['./our-values.component.css'],
})
export class OurValuesComponent implements OnInit {
  missionVision: MissionVision;

  dataLoaded: boolean = false;

  constructor(
    private titleService: Title,
    private missionVisionService: MissionVisionService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('ISE | Değerlerimiz');
    this.getMissions();
  }

  getMissions() {
    this.missionVisionService.getMissions().subscribe((response) => {
      this.missionVision = response.data;
      this.dataLoaded = true;
    });
  }
}
