import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-researchs',
  templateUrl: './researchs.component.html',
  styleUrls: ['./researchs.component.css']
})
export class ResearchsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
