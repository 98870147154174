import { AboutUs } from './../../models/aboutUs';
import { AboutUsService } from './../../services/about-us.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit {
  aboutUs: AboutUs[] = [];
  dataLoaded:boolean= false;

  constructor(
    private titleService: Title,
    private aboutUsService: AboutUsService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('İSG | Hakkımızda');
    this.getAboutUs();
  }
 
  getAboutUs() {
    this.aboutUsService.getAboutUs().subscribe((response) => {
      this.aboutUs = response.data;
      this.dataLoaded = true;
    });
  }
}
