<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>
                <span id="slash">/</span>
                <span id="page-now">Çözüm Ortakları</span>
            </div>
            <div id="header-text">
                <h1>Çözüm Ortakları</h1>
            </div>
        </div>

    </div>

</div>

<div class="loader-page" *ngIf="dataLoaded==false">
     
    <span class="loader"><span class="loader-inner"></span></span> &nbsp; <h4> Lütfen Bekleyiniz...</h4>
</div>

 
<section id="solutionPartners"  *ngIf="dataLoaded">
    <div class="container">


        <div class="content" *ngFor="let solutionPartner of solutionPartners">
            <div class="col-md-3 img-box box">

                <img src="{{solutionPartner.companyImgUrl}}" alt="">
            </div>
            <div class="col-md-6 about-box box">
                <h3 class="header-text"> {{solutionPartner.companyName}}</h3>
                <p class="body-text"> {{solutionPartner.companyAbout}}</p>
            </div>
            <div class="col-md-3 web-address-box box">Web Sitesi
                <a href="{{solutionPartner.webAddress}}" target="_blank"> {{solutionPartner.webAddress}}</a>
            </div>
        </div>



    </div>
</section>