import { OurPointOfViewService } from './../../services/our-point-of-view.service';
import { OurPointOfView } from './../../models/ourPointOfView';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-our-point-of-view',
  templateUrl: './our-point-of-view.component.html',
  styleUrls: ['./our-point-of-view.component.css']
})
export class OurPointOfViewComponent implements OnInit {

  ourPointOfViews: OurPointOfView[]; 
  dataLoaded: boolean = false;
  constructor(
    private titleService: Title,
    private ourPointOfViewService: OurPointOfViewService
  ) { }
  
  ngOnInit(): void {
    this.titleService.setTitle('İSG | Bakışaçımız');
    this.getOurPointOfViews();
  }

  getOurPointOfViews(){
    this.ourPointOfViewService.getOurPointOfViews().subscribe((response) => {
      this.ourPointOfViews = response.data;
      this.dataLoaded = true;
    });
  }
}
