import { Contact } from './../models/contact';
import { Observable } from 'rxjs';
import { ResponseModule } from './../models/responseModule';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../models/globalConstants';

@Injectable({
  providedIn: 'root'
})
export class ContactService {


 
  apiUrl:string = GlobalConstants.apiUrl;    
  constructor(private httpClient:HttpClient) { }
 



  add(contact:Contact):Observable<ResponseModule>{
    return this.httpClient.post<ResponseModule>(this.apiUrl+"contacts/sendmessage",contact);
   }
   
}
