import { ResearchsComponent } from './Components/researchs/researchs.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './Components/footer/footer.component';
import { HomeComponent } from './Components/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NaviComponent } from './Components/navi/navi.component';
import { AboutComponent } from './Components/about/about.component';
import { TeamComponent } from './Components/team/team.component';
import { RegulationsComponent } from './Components/regulations/regulations.component';
import { MissionVisionComponent } from './Components/mission-vision/mission-vision.component';
import { NewsComponent } from './Components/news/news.component';
import { ContactComponent } from './Components/contact/contact.component';
import { SolutionPartnersComponent } from './Components/solution-partners/solution-partners.component';
import { OurValuesComponent } from './Components/our-values/our-values.component';
import { ErrorPageComponent } from './Components/error-page/error-page.component';

import {
  NoopAnimationsModule,
  BrowserAnimationsModule,
} from '@angular/platform-browser/animations';

 
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { OurPointOfViewComponent } from './Components/our-point-of-view/our-point-of-view.component';
import { OurPredictionComponent } from './Components/our-prediction/our-prediction.component';
import { OurGoalComponent } from './Components/our-goal/our-goal.component';
import { IlluminationTextComponent } from './Components/illumination-text/illumination-text.component';
import { ArabImageInTurkisPressComponent } from './Components/researchsComponents/arab-image-in-turkis-press/arab-image-in-turkis-press.component';
import { SoylesiComponent } from './Components/soylesi/soylesi.component';
import { APP_BASE_HREF } from '@angular/common';
import { EarthquakePageComponent } from './Components/researchsComponents/earthquake-page/earthquake-page.component';
 
 

const customNotifierOptions: NotifierOptions = {
	position: {
		horizontal: {
			position: 'left',
			distance: 12
		},
		vertical: {
			position: 'bottom',
			distance: 12,
			gap: 10
		}
	},
	theme: 'material',
	behaviour: {
		autoHide: 5000,
		onClick: false,
		onMouseover: 'pauseAutoHide',
		showDismissButton: true,
		stacking: 4
	},
	animations: {
		enabled: true,
		show: {
			preset: 'slide',
			speed: 300,
			easing: 'ease'
		},
		hide: {
			preset: 'fade',
			speed: 300,
			easing: 'ease',
			offset: 50
		},
		shift: {
			speed: 300,
			easing: 'ease'
		},
		overlap: 150
	}
};

@NgModule({
  declarations: [
    AppComponent,
    NaviComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    TeamComponent,
    RegulationsComponent,
    MissionVisionComponent,
    NewsComponent,
    ContactComponent,
    SolutionPartnersComponent,
    OurValuesComponent,
    ErrorPageComponent,
    OurPointOfViewComponent,
    OurPredictionComponent,
    OurGoalComponent,
    IlluminationTextComponent,
    ResearchsComponent,
    ArabImageInTurkisPressComponent,
    SoylesiComponent,
	EarthquakePageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    NotifierModule.withConfig( customNotifierOptions )
     
  ],
  providers: [
	{ provide: APP_BASE_HREF, useValue: 'https://istanbulsosyalgirisim.org/' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
