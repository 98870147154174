import { ContactService } from './../../services/contact.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact } from 'src/app/models/contact';
 
 


 


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']

})


export class ContactComponent implements OnInit {
  contact: Contact;
  sendMessageForm: FormGroup;
 

  constructor(
    private formBuilder: FormBuilder,
    private titleService: Title,
    private contactService: ContactService 
  ) {
 

  }

  ngOnInit(): void {
    this.titleService.setTitle('İSG | İletişim');
    this.createSendMessageForm();
  }

  createSendMessageForm() {
    let now = new Date();
    this.sendMessageForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      message: ['', Validators.required],
      date: now,
      isActive: true,
    });
  }

  clearFormTexts(){
    this.sendMessageForm.controls['firstName'].setValue("");
    this.sendMessageForm.controls['lastName'].setValue("");
    this.sendMessageForm.controls['message'].setValue("");
    this.sendMessageForm.controls['email'].setValue("");
  }

  
    
  sendMessage() {
    if (this.sendMessageForm.valid) {
      let contactModule = Object.assign({}, this.sendMessageForm.value);

      this.contactService.add(contactModule).subscribe(
        (response) => {
          alert(response.message)
         this.clearFormTexts();
        },
        (responseError) => {
          alert(responseError.message)
      
        }
      );
    } else {
     alert("Lütfen boş alan bırakmayın")
     
    }
  }
}
