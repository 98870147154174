<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>
                <span id="slash">/</span>
                <span id="page-now">Haberler</span>
            </div>
            <div id="header-text">
                <h1>Haberler</h1>
            </div>
        </div>

    </div> 

</div>
<div class="loader-page" *ngIf="dataLoaded==false">
     
    <span class="loader"><span class="loader-inner"></span></span> &nbsp; <h4> Lütfen Bekleyiniz...</h4>
</div>

 
<section id="news" *ngIf="dataLoaded==true">
    <div class="container">

        <div *ngFor="let news of news" class="row">

            <div class="img-box col-md-5">
                <img src="{{news.imgUrl}}" alt=" ">
            </div>
            <div class="content col-md-7 ">
                <h2 class="header ">{{news.newsHeader}}</h2>
                <p class="text ">{{news.newsBody}}
                </p>
                <span class="date"><i class="far fa-calendar-alt "></i>&nbsp; {{news.date | date:'shortDate'}} 
                </span>
            </div>
        </div>





    </div>
</section>