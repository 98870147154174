import { OurGoalService } from './../../services/our-goal.service';
import { Title } from '@angular/platform-browser';
import { OurGoal } from './../../models/ourGoal';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-goal',
  templateUrl: './our-goal.component.html',
  styleUrls: ['./our-goal.component.css'],
})
export class OurGoalComponent implements OnInit {
  
  ourGoals: OurGoal[];
  dataLoaded: boolean = false;

  constructor(
    private titleService: Title,
    private ourGoalService: OurGoalService
  ) {}
 
  ngOnInit(): void {
    this.titleService.setTitle('İSG | Hedefimiz');
    this.getOurGoals();
  } 

  getOurGoals() {
    this.ourGoalService.getOurGoals().subscribe((response) => {
      this.ourGoals = response.data;
      this.dataLoaded = true;
    });
  }


}
