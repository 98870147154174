import { MissionVisionService } from './../../services/mission-vision.service';
import { MissionVision } from './../../models/missionVision';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-mission-vision',
  templateUrl: './mission-vision.component.html',
  styleUrls: ['./mission-vision.component.css'],
})
export class MissionVisionComponent implements OnInit {

 
  missionVision:MissionVision;

 
  
  dataLoaded:boolean=false;

  constructor(
    private titleService: Title,
    private missionVisionService: MissionVisionService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('ISE | Misyon & Vizyon');
    this.getMissions();
  }



  getMissions(){
    this.missionVisionService.getMissions().subscribe((response)=>{
      this.missionVision = response.data;
 
      this.dataLoaded = true;
    })
  }
}
