import { SingleResponseModule } from './../models/singleResponseModule';
import { Observable } from 'rxjs';
import { MissionVision } from './../models/missionVision';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../models/globalConstants';

@Injectable({
  providedIn: 'root'
})
export class MissionVisionService {

 
  apiUrl:string = GlobalConstants.apiUrl;    
  constructor(private httpClient:HttpClient) { }



    //sucriber olunabilen bir ResponseModule dönüceksin
    getMissions():Observable<SingleResponseModule<MissionVision>> {
      let newPath=this.apiUrl+"missionvisions/getbyisactive";
     return this.httpClient.get<SingleResponseModule<MissionVision>>(newPath);
    }
}
