<header>



    <div id="social-media-box-top">
        <div class="box-top">
            <div class="container">
                <div class="mail-box">
                    <a href="mailto: info@istanbulsosyalgirisim.org">info@istanbulsosyalgirisim.org</a> &nbsp; &nbsp;
                    <a href="tel:+902128900018" class="t-phone">+90 212 890 00 18</a>
                </div>
                <div class="social-media">  
                    <span>Bizi Takip Edin :</span>
                    <a href="" target="_blank"><i class="twitter-icon fab fa-twitter-square fa-2x"></i></a>
                    <a href="" target="_blank"><i class="facebook-icon fab fa-facebook-square fa-2x"></i></a>
                    <a href="https://www.instagram.com/istsocialenterprise/" target="_blank"><i class="instagram-icon fab fa-instagram-square fa-2x"></i></a>
                    <a href="https://www.linkedin.com" target="_blank"><i class="linkedin-icon fab fa-linkedin fa-2x"></i></a>
                </div>
            </div>
        </div>
    </div>

    <nav class="navbar navbar-expand-lg navbar-light ">

        <a class="logo" routerLink="">
            <img src="../../../assets/Img/Logo-tr.jpg" alt="" style="width: 100%; height: 100%" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- <div class="logo">
            <h1>İSTANBUL SOSYAL GİRİŞİM</h1>
        </div> -->

   







        <div class="container">

            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav">
                    <li class="nav-item nav-item-default ">
                        <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="">Ana Sayfa <span class="sr-only">(current)</span>
                            </a>
                    </li>
                    <li class="nav-item nav-item-default dropdown">
                        <a class="nav-link dropdown-toggle" href="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Kurumsal
                        </a>
                         <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/hakkimizda"><div class="dropdown-icon"></div> NİYE KURDUK?</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/yonetimKurulu"><div class="dropdown-icon"></div> YÖNETİM KURULU</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/bakisacimiz"><div class="dropdown-icon"></div> BAKIŞ AÇIMIZ</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/ongorumuz"><div class="dropdown-icon"></div> ÖNGÖRÜMÜZ</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/hedefimiz"><div class="dropdown-icon"></div> HEDEFİMİZ</a>
                            <div class="dropdown-divider"></div>
 
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/tuzuk"><div class="dropdown-icon"></div> TÜZÜK</a>
                        </div>
                    </li>
                    
                    <li class="nav-item nav-item-default">
                        <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/haberler">Haberler </a>
                    </li>

                    <li class="nav-item  logo-box">
                        <a class="navbar-brand" routerLink="">
                            <img src="../../../assets/Img/Logo-tr.jpg" alt="" style="width: 100%; height: 100%" />
                        </a>
                    </li>

                    <li class="nav-item nav-item-default">
                        <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/cozumOrtaklari">Çözüm Ortakları</a>
                    </li>
                    <li class="nav-item nav-item-default">
                        <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/arastirmalar">ARAŞTIRMALAR</a>
                    </li>
                    <li class="nav-item nav-item-default">
                        <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/iletisim">İLETİŞİM</a>
                    </li>

                </ul>
            </div>

        </div>
    </nav>
</header>