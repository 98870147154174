<div id="page-top">
    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>
                <span id="slash">/</span>
                <span id="page-now">Kurumsal</span>
                <span id="slash">/</span>
                <span id="page-now">Değerlerimiz</span>
            </div>
            <div id="header-text">
                <h1>Değerlerimiz</h1>
            </div>
        </div>
    </div>
</div>


<div class="loader-page" *ngIf="dataLoaded==false">
     
    <span class="loader"><span class="loader-inner"></span></span> &nbsp; <h4> Lütfen Bekleyiniz...</h4>
</div>

<div id="our-values" *ngIf="dataLoaded">
    <div class="container">
        <div class="content">
            <h3 class="header">Değerlerimiz</h3>
            <p class="text">{{missionVision.ourValuesText}} </p>
        </div>



    </div>
</div>