import { NewsService } from './../../services/news.service';
import { News } from './../../models/news';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit {
  news: News[] = [];
  
  dataLoaded:boolean=false; 
 
   
 
  constructor(private titleService: Title, private newsService: NewsService) {}

  ngOnInit(): void {
    this.titleService.setTitle('İSG | Haberler');
    this.getNews();

  }

  getNews() {
    this.newsService.getNews().subscribe((response) => {
      this.news = response.data;
      this.dataLoaded =true;
    });
  }
}
