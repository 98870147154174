import { SingleResponseModule } from './../models/singleResponseModule';
import { GlobalConstants } from './../models/globalConstants';
import { ResponseModule } from './../models/responseModule';
import { OurGoal } from './../models/ourGoal';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListResponseModule } from '../models/listResponseModule';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OurGoalService {

 
  apiUrl:string = GlobalConstants.apiUrl;    
constructor(
    private httpClient:HttpClient
    ) { }



  getOurGoals():Observable<ListResponseModule<OurGoal>> {
    let newPath=this.apiUrl+"ourgoals/getall";
   return this.httpClient.get<ListResponseModule<OurGoal>>(newPath);
  }
  

 
  

  getFirstOfData():Observable<SingleResponseModule<OurGoal>>{
    let newPath=this.apiUrl+"ourgoals/getfirstofdata"
    return this.httpClient.get<SingleResponseModule<OurGoal>>(newPath);
  }



}
