<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>
                                <span id="slash">/</span>
                <span id="page-now">Kurumsal</span>
                <span id="slash">/</span>
                <span id="page-now">Misyon & Vizyon</span>
            </div>
            <div id="header-text">
                <h1>Misyon & Vizyon</h1>
            </div>
        </div>

    </div>

</div>


<div class="loader-page" *ngIf="dataLoaded==false">
     
    <span class="loader"><span class="loader-inner"></span></span> &nbsp; <h4> Lütfen Bekleyiniz...</h4>
</div>

<section id="missionVision" *ngIf="dataLoaded">
    <div class="container">


        <div class="row">
            <div class="content col-md-7">
                <h2 class="header">Misyon</h2>
                <p class="text"> {{missionVision.missionText}}
                </p>
            </div>
          
 

            <div class="img-box col-md-5" style="justify-content: flex-end;">
                <img src="../../../assets/Img/mission.jpg" alt="">
            </div>
        </div>


        <div class="row">
            <div class="img-box col-md-5" style="justify-content: flex-start;">
                <img src="../../../assets/Img/about-3.jpg" alt="">
            </div>
            <div class="content col-md-7">
                <h2 class="header">Vizyon</h2>
                <p class="text">{{missionVision.visionText}}
                </p>
            </div>

        </div>
    </div>
</section>