<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>
                <span id="slash">/</span>
                <span id="page-now">Kurumsal</span>
                <span id="slash">/</span>
                <span id="page-now">Hedefimiz</span>
            </div>
            <div id="header-text">
                <h1>Hedefimiz</h1>
            </div>
        </div>

    </div> 
</div> 


<div class="loader-page" *ngIf="dataLoaded==false">
     
    <span class="loader"><span class="loader-inner"></span></span> &nbsp; <h4> Lütfen Bekleyiniz...</h4>
</div>


<div id="our-goal" *ngIf="dataLoaded">
    <div class="container">


        <div class="content" *ngFor="let ourGoal of ourGoals">
            <h3 class="header">{{ourGoal.ourGoalHeader}}</h3>
            <p class="text">{{ourGoal.ourGoalText}}
            </p>
        </div>



    </div>
</div>


