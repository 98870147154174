<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>
                <span id="slash">/</span>
                <span id="page-now">Kurumsal</span>
                <span id="slash">/</span>
                <span id="page-now">Tüzük</span>
            </div>
            <div id="header-text">
                <h1>Tüzük</h1>
            </div>
        </div>

    </div>
</div>
<!-- 
<div id="fast-navi-box">
    <ul>
        <li><a href="tuzuk/#madde1">Merkez</a></li>
        <li><a href="tuzuk/#madde2">Amaç</a></li>
        <li><a href="tuzuk/#madde3">Üyelik İşlemleri</a></li>
        <li><a href="tuzuk/#madde4">Dernek Organları</a></li>
        <li><a href="tuzuk/#madde5">Genel Kurul</a></li>
        <li><a href="tuzuk/#madde6">Yönetim Kurulu</a></li>
        <li><a href="tuzuk/#madde7">Denetim Kurulu</a></li>
        <li><a href="tuzuk/#madde8">Madde 8</a></li>
        <li><a href="tuzuk/#madde9">Madde 9</a></li>
        <li><a href="tuzuk/#madde10">Madde 10</a></li>
        <li><a href="tuzuk/#madde11">Madde 11</a></li>
        <li><a href="tuzuk/#madde12">Madde 12</a></li>
        <li><a href="tuzuk/#madde13">Madde 13</a></li>
        <li><a href="tuzuk/#madde14">Madde 14</a></li>
        <li><a href="tuzuk/#madde15">Madde 15</a></li>
        <li><a href="tuzuk/#madde16">Madde 16</a></li>
        <li><a href="tuzuk/#madde17">Madde 17</a></li>
        <li><a href="tuzuk/#madde18">Madde 18</a></li>
        <li><a href="tuzuk/#madde19">Madde 19</a></li>
        <li><a href="tuzuk/#madde20">Madde 20</a></li>
        <li><a href="tuzuk/#madde21">Madde 21</a></li>
        <li><a href="tuzuk/#madde22">Madde 22</a></li>
        <li><a href="tuzuk/#madde23">Madde 23</a></li>
        <li><a href="tuzuk/#madde24">Madde 24</a></li>
        <li><a href="tuzuk/#madde25">Madde 25</a></li>
        <li><a href="tuzuk/#madde26">Madde 26</a></li>





    </ul>
</div> -->

<section id="tuzuk">
    <div class="container" id="madde1">
        <h2>İstanbul Sosyal Girişim (Istanbul Social Enterprise) Derneği Tüzüğü</h2>
        <div class="content">

            <h5 class="header">
                <span class="madde"><strong>Merkez</strong></span>
                <br><br> - Madde 1
            </h5>

            <ul class="madde-ol">
                <li>Derneğin Adı: <br>İstanbul Sosyal Girişim (Istanbul Social Enterprise) Derneği</li>
                <li>Merkezi: <br> Derneğin İngilizce adı Istanbul Social Enterprise olup kısa olarak Istanbul SE olarak
                    kullanılacaktır. <br> Parima İş Merkezi, No: 8/9, 34010 Maltepe Mah </li>
                <li>Kısaltma: <br> Derneğin Adı: “İstanbul Sosyal Girişim” olup kısa olarak İstanbul SG olarak
                    kullanılacaktır. <br> Derneğin İngilizce adı Istanbul Social Enterprise olup kısa olarak Istanbul SE
                    olarak kullanılacaktır.</li>
                <li>Merkezi:</li>
            </ul>

        </div>



        <div class="content" id="madde2">
            <h5 class="header">
                <span class="madde"><strong>Amaç</strong></span>
                <br><br> - Madde 2
            </h5>




            <p class="text">
                Dernek, sivil toplum faaliyetlerinin etkinleştirilmesi ve geliştirilmesini sağlamak ve bu konuda
                çalışmalar yapan kişi ve kuruluşlara destek vermek amacı ile kurulmuştur.
            </p>
            <h5 class="header">Dernekçe Sürdürülecek Çalışma Konuları ve Biçimleri</h5>

            <ol class="madde-ol">
                <p class="text">
                    <li>Faaliyetlerinin etkinleştirilmesi ve geliştirilmesi için araştırmalar yapmak, </li>
                    <li>Kurs, seminer, konferans ve panel gibi eğitim çalışmaları düzenlemek, </li>
                    <li>Kamuoyu araştırmaları yapmak. </li>
                    <li>Sempozyum, kongre, konferans vb. düzenlemek.</li>
                    <li> İnternet üzerinden ve geleneksel yöntemlerle genel, akademik ve mesleki eğitim kursları ve
                        sertifika programları düzenlemek.</li>
                    <li>Faaliyetleriyle ilgili İnternet yayıncılığı yapmak.</li>
                    <li>Basılı, görsel ve elektronik süreli ve süresiz yayıncılık yapmak.</li>
                    <li>Sosyal, ticari ve teknolojik alanlarda proje hazırlamak ve danışmanlık yapmak.</li>
                    <li>Sosyal girişim faaliyetlerini yürütmek.</li>
                    <li> Sosyal sorunlara ticari çözümler üretmek.</li>
                    <li> Sosyal inovasyon faaliyeti yürütmek.</li>
                    <li> Amacın gerçekleştirilmesi için gerekli olan her türlü bilgi, belge, doküman ve yayınları temin
                        etmek, dokümantasyon merkezi oluşturmak, çalışmalarını duyurmak için amaçları doğrultusunda
                        gazete, dergi, kitap ve bülten gibi yayınlar
                        çıkarmak,
                    </li>
                    <li> Amacın gerçekleştirilmesi için sağlıklı bir çalışma ortamını sağlamak, her türlü teknik araç ve
                        gereci, demirbaş ve kırtasiye malzemelerini temin etmek,</li>
                    <li> Gerekli izinler alınmak şartıyla yardım toplama faaliyetlerinde bulunmak ve yurt içinden ve
                        yurt dışından bağış kabul etmek,</li>
                    <li> Tüzük amaçlarının gerçekleştirilmesi için ihtiyaç duyduğu gelirleri temin etmek amacıyla
                        iktisadi, ticari ve sınai işletmeler kurmak ve işletmek, </li>

                    <li> Üyelerinin yararlanmaları ve boş zamanlarını değerlendirebilmeleri için lokal açmak, sosyal ve
                        kültürel tesisler kurmak ve bunları tefriş etmek, </li>
                    <li> Üyeleri arasında beşerî münasebetlerin geliştirilmesi ve devam ettirilmesi için yemekli
                        toplantılar, konser, balo, tiyatro, sergi, spor, gezi ve eğlenceli etkinlikler vb. düzenlemek
                        veya üyelerinin bu tür etkinliklerden yararlanmalarını
                        sağlamak,
                    </li>
                    <li> Dernek faaliyetleri için ihtiyaç duyulan taşınır, taşınmaz mal satın almak, satmak, kiralamak,
                        kiraya vermek ve taşınmazlar üzerinde ayni hak tesis etmek,</li>
                    <li> Amacın gerçekleştirilmesi için gerek görülmesi durumunda yurt içinde ve yurt dışında vakıf
                        kurmak, federasyon kurmak veya kurulu bir federasyona katılmak, gerekli izin alınarak
                        derneklerin kurabileceği tesisleri kurmak,</li>
                    <li> Uluslararası faaliyette bulunmak, yurt dışındaki dernek veya kuruluşlara üye olmak ve bu
                        kuruluşlarla ortak çalışmalar yapmak veya yardımlaşmak,</li>
                    <li> Amacın gerçekleştirilmesi için gerek görülmesi halinde, 5072 sayılı Dernek ve Vakıfların Kamu
                        Kurum ve Kuruluşları ile İlişkilerine Dair Kanun hükümleri saklı kalmak üzere, kamu kurum ve
                        kuruluşları ile görev alanlarına giren konularda
                        ortak projeler yürütmek,</li>
                    <li> Dernek üyelerinin yiyecek, giyecek gibi zaruri ihtiyaç maddelerini ve diğer mal ve hizmetlerle
                        kısa vadeli kredi ihtiyaçlarını karşılamak amacıyla sandık kurmak,</li>
                    <li> Gerekli görülen yerlerde şube ve temsilcilikler açmak,</li>
                    <li> Derneğin amacı ile ilgisi bulunan ve kanunlarla yasaklanmayan alanlarda, diğer derneklerle veya
                        vakıf, sendika ve benzeri sivil toplum kuruluşlarıyla ortak bir amacı gerçekleştirmek için
                        plâtformlar oluşturmak,</li>
                    <li> Amacın gerçekleştirilmesi için ihtiyaç duyulan ve kanunların yasaklamadığı her türlü faaliyette
                        bulunmak,</li>
                </p>
            </ol>

            <h5 class="header">Derneğin Faaliyet Alanı</h5>
            <p class="text">Dernek, sosyal alanda yurt içinde ve yurt dışında faaliyet gösterir.</p>

        </div>










        <div class="content" id="madde3">
            <h5 class="header">
                <span class="madde"><strong>Üye Olma Hakkı ve Üyelik İşlemleri</strong></span>
                <br><br> - Madde 3
            </h5>

            <p class="text">
                Fiil ehliyetine sahip bulunan ve derneğin amaç ve ilkelerini benimseyerek bu doğrultuda çalışmayı kabul
                eden ve Mevzuatın öngördüğü koşullarını taşıyan her gerçek ve tüzel kişi bu derneğe üye olma hakkına
                sahiptir. Ancak, yabancı gerçek kişilerin üye
                olabilmesi için Türkiye’de yerleşme hakkına sahip olması da gerekir. Onursal üyelik için bu koşul
                aranmaz.

            </p>


            <p class="text">
                Dernek başkanlığına yazılı olarak veya elektronik ortam üzerinden yapılacak üyelik başvurusu, dernek
                yönetim kurulunca en çok otuz gün içinde üyeliğe kabul veya isteğin reddi şeklinde karara bağlanır ve
                sonuç, elektronik ortam üzerinden veya yazıyla başvuru
                sahibine bildirilir. Başvurusu kabul edilen üye, bu amaçla tutulacak deftere kaydedilir.

            </p>


            <p class="text">
                Derneğin asıl üyeleri, derneğin kurucuları ile müracaatları üzerine yönetim kurulunca üyeliğe kabul
                edilen kişilerdir.

            </p>


            <p class="text">
                Derneğe maddi ve manevi bakımdan önemli destek sağlamış bulunanlar yönetim kurulu kararı ile onursal üye
                olarak kabul edilebilir.

            </p>


            <p class="text">
                Derneğin şube sayısı üçten fazla olduğunda dernek merkezinde kayıtlı bulunanların üyelik kayıtları
                şubelere aktarılır. Yeni üyelik müracaatları şubelere yapılır. Üyeliğe kabul ve üyelikten silinme
                işlemleri şube yönetim kurulları tarafından yapılır ve
                en çok otuz gün içinde bir yazıyla Genel Merkeze bildirilir.

            </p>





        </div>

        <div class="content" id="madde4">
            <h5 class="header">
                <span class="madde"><strong>Üyelikten Çıkma:</strong> </span>
                <br><br> - Madde 4
            </h5>
            <p class="text">
                Her üye yazılı olarak bildirmek kaydıyla, dernekten çıkma hakkına sahiptir. </p>
            <p class="text">
                Üyenin istifa dilekçesi yönetim kuruluna ulaştığı anda çıkış işlemleri sonuçlanmış sayılır. Üyelikten
                ayrılma, üyenin derneğe olan birikmiş borçlarını sona erdirmez. </p>

        </div>


        <div class="content" id="madde5">
            <h5 class="header">
                <span class="madde"><strong>Üyelikten Çıkarılma:</strong> </span>
                <br><br> - Madde 5
            </h5>
            <p class="text">
                Dernek üyeliğinden çıkarılmayı gerektiren haller. </p>
            <ul class="madde-ol">
                <li> Dernek tüzüğüne aykırı davranışlarda bulunmak,</li>
                <li> Verilen görevlerden sürekli kaçınmak, </li>
                <li> Yazılı ikazlara rağmen üyelik aidatını altı ay içinde ödememek,</li>
                <li> Dernek organlarınca verilen kararlara uymamak.</li>
                <li> Üye olma şartlarını kaybetmiş olmak,</li>
            </ul>
            <p class="text">Yukarıda sayılan durumlardan birinin tespiti halinde yönetim kurulu kararı ile üyelikten
                çıkarılabilir. Dernekten çıkan veya çıkarılanlar, üye kayıt defterinden silinir ve dernek malvarlığında
                hak iddia edemez.
            </p>
        </div>




        <div class="content" id="madde6">
            <h5 class="header">
                <span class="madde"><strong>Dernek Organları:</strong></span>
                <br><br> - Madde 6
            </h5>
            <p class="text">
                Derneğin organları aşağıda gösterilmiştir. </p>
            <ul class="madde-ol">
                <li>Genel kurul.</li>
                <li>Yönetim kurulu.</li>
                <li>Denetim kurulu.</li>
            </ul>

        </div>





        <div class="content" id="madde7">
            <h5 class="header">
                <span class="madde"><strong>Dernek Genel Kurulunun Kuruluş Şekli, Toplanma Zamanı ve Çağrı ve Toplantı
                        Usulü:</strong> </span>
                <br><br> - Madde 7
            </h5>
            <p class="text">
                Genel kurul, derneğin en yetkili karar organı olup; derneğe kayıtlı üyelerden oluşur. Derneğin şubesinin
                açılması durumunda ise şube sayısı üçe kadar genel merkez ve şubelerinde kayıtlı üyelerden; şube sayısı
                üçten fazla olması durumunda ise genel merkezdeki
                kayıtlı üyeler şubelere nakledilerek şubelerin genel kurullarında seçilen delegelerden oluşur.
            </p>
            <p class="text"> Genel kurul;</p>
            <ul class="madde-ol">
                <p class="text">
                    <li> Bu tüzükte belli edilen zamanda olağan,</li>
                    <li> Yönetim veya denetim kurulunun gerekli gördüğü hallerde veya dernek üyelerinden beşte birinin
                        yazılı başvurusu üzerine, yönetim kurulunca olağanüstü toplantıya çağrılır. Yönetim kurulu,
                        genel kurulu toplantıya çağırmazsa; üyelerden
                        birinin başvurusu üzerine sulh hâkimi, üç üyeyi genel kurulu toplantıya çağırmakla görevlendirir
                        <br>Olağan genel kurul, 3 yılda bir, Şubat ayı içerisinde, yönetim kurulunca belirlenecek gün
                        yer ve saatte toplanır.
                    </li>
                </p>
            </ul>

            <p class="text"><strong>Çağrı Usulü*</strong></p>
            <p class="text">
                Yönetim kurulu, dernek tüzüğüne göre genel kurula katılma hakkı bulunan üyelerin listesini düzenler.
                Genel kurula katılma hakkı bulunan üyeler, en az onbeş gün önceden, toplantının günü, saati, yeri ve
                gündemi en az bir gazetede veya derneğin internet
                sayfasında ilan edilmek, yazılı olarak bildirilmek, üyenin bildirdiği elektronik posta adresine ya da
                iletişim numarasına mesaj gönderilmek veya mahalli yayın araçları kullanılmak suretiyle toplantıya
                çağrılır. Bu çağrıda, çoğunluk sağlanamaması
                sebebiyle toplantı yapılamazsa, ikinci toplantının hangi gün, saat ve yerde yapılacağı da belirtilir.
                İlk toplantı ile ikinci toplantı arasındaki süre yedi günden az, altmış günden fazla olamaz.
            </p>
            <p class="text">Toplantı, çoğunluk sağlanamaması sebebinin dışında başka bir nedenle geri bırakılırsa, bu
                durum geri bırakma sebepleri de belirtilmek suretiyle, ilk toplantı için yapılan çağrı usulüne uygun
                olarak üyelere duyurulur. İkinci toplantının geri
                bırakma tarihinden itibaren en geç altı ay içinde yapılması zorunludur. Üyeler ikinci toplantıya,
                birinci fıkrada belirtilen esaslara göre yeniden çağrılır.</p>
            <p class="text">Genel kurul toplantısı bir defadan fazla geri bırakılamaz.</p>

            <p class="text"><strong>Toplantı Usulü*</strong></p>
            <p class="text">Genel kurul, katılma hakkı bulunan üyelerin salt çoğunluğunun, tüzük değişikliği ve derneğin
                feshi hallerinde ise üçte ikisinin katılımıyla toplanır; çoğunluğun sağlanamaması sebebiyle toplantının
                ertelenmesi durumunda ikinci toplantıda çoğunluk
                aranmaz. Ancak, bu toplantıya katılan üye sayısı, yönetim ve denetim kurulları üye tam sayısının iki
                katından az olamaz.

            </p>
            <p class="text">Genel kurula katılma hakkı bulunan üyelerin listesi toplantı yerinde hazır bulundurulur.
                Toplantı yerine girecek üyelerin resmi makamlarca verilmiş kimlik belgeleri, yönetim kurulu üyeleri veya
                yönetim kurulunca görevlendirilecek görevliler
                tarafından kontrol edilir. Üyeler, yönetim kurulunca düzenlenen listedeki adları karşısına imza koyarak
                toplantı yerine girerler.</p>

            <p class="text">Toplantı yeter sayısı sağlanmışsa durum bir tutanakla tespit edilir ve toplantı yönetim
                kurulu başkanı veya görevlendireceği yönetim kurulu üyelerinden biri tarafından açılır. Toplantı yeter
                sayısı sağlanamaması halinde de yönetim kurulunca
                bir tutanak düzenlenir.</p>
            <p class="text">Açılıştan sonra, toplantıyı yönetmek üzere bir başkan ve yeteri kadar başkan vekili ile
                yazman seçilerek divan heyeti oluşturulur.</p>

            <p class="text">Dernek organlarının seçimi için yapılacak oylamalarda, oy kullanan üyelerin divan heyetine
                kimliklerini göstermeleri ve hazirun listesindeki isimlerinin karşılarını imzalamaları zorunludur. </p>
            <p class="text">Toplantının yönetimi ve güvenliğinin sağlanması divan başkanına aittir. </p>

            <p class="text">Genel kurulda, yalnızca gündemde yer alan maddeler görüşülür. Ancak toplantıda hazır bulunan
                üyelerin onda biri tarafından görüşülmesi yazılı olarak istenen konuların gündeme alınması zorunludur.
            </p>
            <p class="text">Genel kurulda her üyenin bir oy hakkı vardır; üye oyunu şahsen kullanmak zorundadır. Onursal
                üyeler genel kurul toplantılarına katılabilir ancak oy kullanamazlar. Tüzel kişinin üye olması halinde,
                tüzel kişinin yönetim kurulu başkanı veya
                temsille görevlendireceği kişi oy kullanır.</p>
            <p class="text">Toplantıda görüşülen konular ve alınan kararlar bir tutanağa yazılır ve divan başkanı ile
                yazmanlar tarafından birlikte imzalanır. Toplantı sonunda, tutanak ve diğer belgeler yönetim kurulu
                başkanına teslim edilir. Yönetim kurulu başkanı bu
                belgelerin korunmasından ve yeni seçilen yönetim kuruluna yedi gün içinde teslim etmekten sorumludur.
            </p>


        </div>





        <div class="content" id="madde8">
            <h5 class="header">
                <span class="madde"><strong>Genel Kurulun Oy kullanma ve Karar Alma Usul ve Şekilleri:</strong> </span>
                <br><br> - Madde 8
            </h5>
            <p class="text">Genel kurulda, aksine karar alınmamışsa, oylamalar açık olarak yapılır. Açık oylamada, genel
                kurul başkanının belirteceği yöntem uygulanır. </p>



            <p class="text">Gizli oylama yapılacak olması durumunda ise, toplantı başkanı tarafından mühürlenmiş
                kağıtlar veya oy pusulaları üyeler tarafından gereği yapıldıktan sonra içi boş bir kaba atılır ve oy
                vermenin bitiminden sonra açık dökümü yapılarak sonuç
                belirlenir.
            </p>
            <p class="text">Genel kurul kararları, toplantıya katılan üyelerin salt çoğunluğuyla alınır. Şu kadar ki,
                tüzük değişikliği ve derneğin feshi kararları, ancak toplantıya katılan üyelerin üçte iki çoğunluğuyla
                alınabilir.</p>

            <p class="text"><strong>Toplantısız veya Çağrısız Alınan Kararlar*</strong></p>
            <p class="text">Bütün üyelerin bir araya gelmeksizin yazılı katılımıyla alınan kararlar ile dernek
                üyelerinin tamamının bu tüzükte yazılı çağrı usulüne uymaksızın bir araya gelerek aldığı kararlar
                geçerlidir. Bu şekilde karar alınması olağan toplantı yerine
                geçmez.
            </p>



        </div>





        <div class="content" id="madde9">
            <h5 class="header">
                <span class="madde"><strong>Genel Kurulun Görev ve Yetkileri:</strong> </span>
                <br><br> - Madde 9
            </h5>
            <p class="text">
                Aşağıda yazılı hususlar genel kurulca görüşülüp karara bağlanır. </p>

            <ol class="madde-ol">
                <p class="text">
                    <li> Dernek organlarının seçilmesi,</li>
                    <li> Dernek tüzüğünün değiştirilmesi,</li>
                    <li> Yönetim ve denetim kurulları raporlarının görüşülmesi ve yönetim kurulunun ibrası,</li>
                    <li> Yönetim kurulunca hazırlanan bütçenin görüşülüp aynen veya değiştirilerek kabul edilmesi,</li>
                    <li> Derneğin diğer organlarının denetlenmesi ve gerek görüldüğünde haklı sebeplerle onların
                        görevden alınması,</li>
                    <li> Üyeliğin reddi veya üyelikten çıkarma hakkında verilen yönetim kurulu kararlarına karşı yapılan
                        itirazların incelenmesi ve karara bağlanması,</li>
                    <li> Dernek için gerekli olan taşınmaz malların satın alınması veya mevcut taşınmaz malların
                        satılması hususunda yönetim kuruluna yetki verilmesi,</li>
                    <li> Yönetim kurulunca dernek çalışmaları ile ilgili olarak hazırlanacak yönetmelikleri inceleyip
                        aynen veya değiştirilerek onaylanması,</li>
                    <li> Dernek yönetim ve denetim kurullarının kamu görevlisi olmayan başkan ve üyelerine verilecek
                        ücret ile her türlü ödenek, yolluk ve tazminatlar ile dernek hizmetleri için görevlendirilecek
                        üyelere verilecek gündelik ve yolluk miktarlarının
                        tespit edilmesi,</li>
                    <li> Derneğin federasyona katılması ve ayrılmasının kararlaştırılması,</li>
                    <li> Derneğin şubelerinin açılmasının kararlaştırılması ve açılmasına karar verilen şube ile ilgili
                        işlemlerin yürütülmesi hususunda yönetim kuruluna yetki verilmesi,</li>
                    <li> Derneğin uluslar arası faaliyette bulunması, yurt dışındaki dernek ve kuruluşlara üye olarak
                        katılması veya ayrılması,</li>
                    <li> Derneğin vakıf kurması,</li>
                    <li> Derneğin feshedilmesi,</li>
                    <li> Yönetim kurulunun diğer önerilerinin incelenip karara bağlanması,</li>
                    <li> Derneğin en yetkili organı olarak derneğin diğer bir organına verilmemiş olan işlerin görülmesi
                        ve yetkilerin kullanılması,</li>
                    <li> Mevzuatta genel kurulca yapılması belirtilen diğer görevlerin yerine getirilmesi.</li>
                </p>
            </ol>
        </div>





        <div class="content" id="madde10">
            <h5 class="header">
                <span class="madde"><strong>Yönetim Kurulunun Teşkili, Görev ve Yetkileri:</strong> </span>
                <br><br> - Madde 10
            </h5>
            <p class="text">Yönetim kurulu beş asıl ve beş yedek üye olarak genel kurulca seçilir. </p>
            <p class="text">Yönetim kurulu, seçimden sonraki ilk toplantısında bir kararla görev bölüşümü yaparak
                başkan, en fazla üç başkan yardımcısı, sekreter, sayman ve üyeyi belirler. </p>
            <p class="text"> Yönetim kurulu asıl üyeliğinde istifa veya başka sebeplerden dolayı boşalma olduğu taktirde
                genel kurulda aldığı oy çokluğu sırasına göre yedek üyelerin göreve çağrılması mecburidir. </p>
            <br>
            <p class="text"> Yönetim Kurulunun Görev ve Yetkileri </p>
            <p class="text"> Yönetim kurulu aşağıdaki hususları yerine getirir.</p>

            <ol class="madde-ol">
                <p class="text">
                    <li> Derneği temsil etmek veya bu hususta kendi üyelerinden birine veya bir üçüncü kişiye yetki
                        vermek,</li>
                    <li> Gelir ve gider hesaplarına ilişkin işlemleri yapmak ve gelecek döneme ait bütçeyi hazırlayarak
                        genel kurula sunmak,</li>
                    <li> Derneğin çalışmaları ile ilgili yönetmelikleri hazırlayarak genel kurul onayına sunmak,</li>
                    <li> Genel kurulun verdiği yetki ile taşınmaz mal satın almak, derneğe ait taşınır ve taşınmaz
                        malları satmak, bina veya tesis inşa ettirmek, kira sözleşmesi yapmak, dernek lehine rehin
                        ipotek veya ayni haklar tesis ettirmek,</li>
                    <li> Genel kurulun verdiği yetki ile şube açmaya ilişkin işlemlerin yürütülmesini sağlamak,</li>
                    <li> Derneğin şubelerinin denetlenmesini sağlamak,</li>
                    <li> Gereli görülen yerlerde temsilcilik açılmasını sağlamak,</li>
                    <li> Genel kurulda alınan kararları uygulamak,</li>
                    <li> Her faaliyet yılı sonunda derneğin işletme hesabı tablosu veya bilanço ve gelir tablosu ile
                        yönetim kurulu çalışmalarını açıklayan raporunu düzenlemek, toplandığında genel kurula sunmak,
                    </li>
                    <li> Bütçenin uygulanmasını sağlamak,</li>
                    <li> Derneğe üye alınması veya üyelikten çıkarılma hususlarında karar vermek,</li>
                    <li> Derneğin amacını gerçekleştirmek için yetkisi dahilinde her çeşit kararı almak ve uygulamak,
                    </li>
                    <li> Mevzuatın kendisine verdiği diğer görevleri yapmak ve yetkileri kullanmak.</li>
                </p>
            </ol>
        </div>





        <div class="content">
            <h5 class="header">
                <span class="madde"><strong>Denetim Kurulunun Teşkili, Görev ve Yetkileri:</strong> </span>
                <br><br> - Madde 11
            </h5>
            <p class="text">Denetim kurulu, üç asıl ve üç yedek üye olarak genel kurulca seçilir. </p>
            <p class="text"> Denetim kurulu asıl üyeliğinde istifa veya başka sebeplerden dolayı boşalma olduğu taktirde
                genel kurulda aldığı oy çokluğu sırasına göre yedek üyelerin göreve çağrılması mecburidir.</p>
            <p class="text">Denetim Kurulunun Görev ve Yetkileri</p>
            <br>
            <p class="text"> Denetim kurulu; derneğin, tüzüğünde gösterilen amaç ve amacın gerçekleştirilmesi için
                sürdürüleceği belirtilen çalışma konuları doğrultusunda faaliyet gösterip göstermediğini, defter, hesap
                ve kayıtların mevzuata ve dernek tüzüğüne uygun olarak
                tutulup tutulmadığını, dernek tüzüğünde tespit edilen esas ve usullere göre ve bir yılı geçmeyen
                aralıklarla denetler ve denetim sonuçlarını bir rapor halinde yönetim kuruluna ve toplandığında genel
                kurula sunar.</p>
            <p class="text"> Denetim kurulu, gerektiğinde genel kurulun toplantıya çağrılmasını isteyebilir.</p>
        </div>





        <div class="content">
            <h5 class="header">
                <span class="madde"> <strong>Derneğin Gelir Kaynakları:</strong></span>
                <br><br> - Madde 12
            </h5>
            <p class="text">Derneğin gelir kaynakları aşağıda sayılmıştır. </p>
            <ol class="madde-ol">
                <p class="text">
                    <li> Üye aidatı: Üyelerden giriş ödentisi olarak 100 TL, aylık olarak da 10 TL aidat alınır. Bu
                        miktarları artırmaya veya eksiltmeye genel kurul yetkilidir, </li>
                    <li> Şube ödentisi: Derneğin genel giderlerini karşılamak üzere şubeler tarafından tahsil edilen üye
                        ödentilerinin %50’si altı ayda bir genel merkeze gönderilir,</li>
                    <li> Gerçek ve tüzel kişilerin kendi isteği ile derneğe yaptıkları bağış ve yardımlar,</li>
                    <li> Dernek tarafından tertiplenen çay ve yemekli toplantı, gezi ve eğlence, temsil, konser, spor
                        yarışması ve konferans gibi faaliyetlerden sağlanan gelirler,</li>
                    <li> Derneğin mal varlığından elde edilen gelirler,</li>
                    <li> Yardım toplama hakkındaki mevzuat hükümlerine uygun olarak toplanacak bağış ve yardımlar,</li>
                    <li> Derneğin, amacını gerçekleştirmek için ihtiyaç duyduğu geliri temin etmek amacıyla giriştiği
                        ticari faaliyetlerden elde edilen kazançlar,</li>
                    <li> Diğer gelirler. </li>
                </p>
            </ol>

        </div>





        <div class="content" id="madde13">
            <h5 class="header">
                <span class="madde"> <strong>Derneğin Defter Tutma Esas ve Usulleri ve Tutulacak
                        Defterler*:</strong></span>
                <br><br> - Madde 13
            </h5>
            <p class="text">Defter tutma esasları; </p>
            <p class="text">Dernekte, işletme hesabı esasına göre defter tutulur. Ancak, yıllık brüt gelirin Dernekler
                Yönetmeliğinin 31. Maddesinde belirtilen haddi aşması durumunda takip eden hesap döneminden başlayarak
                bilanço esasına göre defter tutulur. </p>
            <p class="text">Bilanço esasına geçilmesi durumunda, üst üste iki hesap döneminde yukarıda belirtilen haddin
                altına düşülürse, takip eden yıldan itibaren işletme hesabı esasına dönülebilir. </p>
            <p class="text">Yukarıda belirtilen hadde bağlı kalmaksızın yönetim kurulu kararı ile bilanço esasına göre
                defter tutulabilir.</p>
            <p class="text">Derneğin ticari işletmesi açılması durumunda, bu ticari işletme için, ayrıca Vergi Usul
                Kanunu hükümlerine göre defter tutulur.</p>
            <p class="text">Kayıt Usulü</p>
            <p class="text">Derneğin defter ve kayıtları Dernekler Yönetmeliğinde belirtilen usul ve esasa uygun olarak
                tutulur. </p><br>
            <p class="text">Tutulacak Defterler</p>
            <p class="text">Dernekte, aşağıda yazılı defterler tutulur.</p><br>

            <p class="text">a) İşletme hesabı esasında tutulacak defterler ve uyulacak esaslar aşağıdaki gibidir:</p>

            <ol class="madde-ol">

                <p class="text">


                    <li>Karar Defteri: Yönetim kurulu kararları tarih ve numara sırasıyla bu deftere yazılır ve
                        kararların altı toplantıya katılan üyelerce imzalanır.</li>
                    <li>Üye Kayıt Defteri: Derneğe üye olarak girenlerin kimlik bilgileri, derneğe giriş ve çıkış
                        tarihleri bu deftere işlenir. Üyelerin ödedikleri giriş ve yıllık aidat miktarları bu deftere
                        işlenebilir.</li>
                    <li>Evrak Kayıt Defteri: Gelen ve giden evraklar, tarih ve sıra numarası ile bu deftere kaydedilir.
                        Gelen evrakın asılları ve giden evrakın kopyaları dosyalanır. Elektronik posta yoluyla gelen
                        veya giden evraklar çıktısı alınmak suretiyle saklanır. </li>
                    <li>İşletme Hesabı Defteri: Dernek adına alınan gelirler ve yapılan giderler açık ve düzenli olarak
                        bu deftere işlenir.</li>
                    <li>Alındı Belgesi Kayıt Defteri: Alındı belgelerinin seri ve sıra numaraları, bu belgeleri alan ve
                        iade edelerin adı, soyadı ve imzaları ile aldıkları ve iade ettikleri tarihler bu deftere
                        işlenir. </li>
                    <li>Demirbaş Defteri: Derneğe ait demirbaşların edinme tarihi ve şekli ile kullanıldıkları veya
                        verildikleri yerler ve kullanım sürelerini dolduranların kayıttan düşürülmesi bu deftere
                        işlenir. </li>
                </p>
                <p class="text">Alındı Belgesi Kayıt Defteri ile Demirbaş Defterinin tutulması zorunlu değildir. </p>
            </ol>

            <br>

            <p class="text">b) Bilanço esasında tutulacak defterler ve uyulacak esaslar aşağıdaki gibidir:</p>


            <ol class="madde-ol">
                <p class="text">
                    <li>(a) bendinin 1, 2 ve 3 üncü alt bentlerinde kayıtlı defterler bilanço esasında defter tutulması
                        durumunda da tutulur.</li>
                    <li>Yevmiye Defteri ve Büyük Defter: Bu defterlerin tutulma usulü ile kayıt şekli Vergi Usul Kanunu
                        ile bu Kanununun Maliye Bakanlığına verdiği yetkiye istinaden yayımlanan Muhasebe Sistemi
                        Uygulama Genel Tebliğleri esaslarına göre yapılır.</li>
                </p>
            </ol>


            <br>
            <i class="text">Defterlerin Tasdiki</i>
            <p class="text">Dernekte, tutulması zorunlu olan defterler (Büyük Defter hariç), kullanmaya başlamadan önce
                İl Sivil Toplumla ilişkiler Müdürlüğüne veya notere tasdik ettirilir. Bu defterlerin kullanılmasına
                sayfaları bitene kadar devam edilir ve defterlerin ara tasdiki yapılmaz. Ancak, bilanço esasına göre
                tutulan Yevmiye Defteri’nin kullanılacağı yıldan önce gelen son ayda, her yıl yeniden tasdik ettirilmesi
                zorunludur.</p>
            <i class="text">Gelir Tablosu ve Bilanço Düzenlenmesi</i>
            <p class="text">İşletme hesabı esasına göre kayıt tutulması durumunda yıl sonlarında (31 Aralık) (Dernekler
                Yönetmeliği EK-16’da belirtilen) “İşletme Hesabı Tablosu” düzenlenir. Bilanço esasına göre defter
                tutulması durumunda ise, yılsonlarında (31 Aralık), Maliye Bakanlığınca yayımlanan Muhasebe Sistemi
                Uygulama Genel Tebliğlerini esas alarak bilanço ve gelir tablosu düzenlenir.</p>

        </div>





        <div class="content">
            <h5 class="header">
                <span class="madde">Derneğin Gelir ve Gider İşlemleri*: </span>
                <br><br> - Madde 14
            </h5>
            <p class="text">
                Dernek gelirleri, “Alındı Belgesi” ile tahsil edilir. Dernek gelirlerinin bankalar aracılığı ile tahsili
                halinde banka tarafından düzenlenen dekont veya hesap özeti gibi belgeler alındı belgesi yerine geçer.
            </p>

            <p class="text">Dernek giderleri ise fatura, perakende satış fişi, serbest meslek makbuzu gibi harcama
                belgeleri ile yapılır. Ancak derneğin, Gelir Vergisi Kanununun 94’üncü maddesi kapsamında bulunan
                ödemeleri için Vergi Usul Kanunu hükümlerine göre gider pusulası, bu kapsamda da bulunmayan ödemeleri
                için “Gider Makbuzu” veya “Banka Dekontu” gibi belgeler harcama belgesi olarak kullanılır.</p>


            <p class="text">Dernek tarafından kişi, kurum veya kuruluşlara yapılacak bedelsiz mal ve hizmet teslimleri
                “Ayni Yardım Teslim Belgesi” ile yapılır. Kişi, kurum veya kuruluşlar tarafından derneğe yapılacak
                bedelsiz mal ve hizmet teslimleri ise “Ayni Bağış Alındı Belgesi” ile kabul edilir. </p>
            <p class="text">Bu belgeler; müteselsil seri ve sıra numarası taşıyan, kendinden karbonlu elli asıl ve elli
                koçan yaprağından meydana gelen ciltler veya elektronik sistemler ve yazı makineleri aracılığıyla
                yazdırılacak form veya sürekli form şeklinde bastırılır. Form veya sürekli form şeklinde bastırılacak
                belgelerin, belirtilen nitelikte olması zorunludur.</p>
            <i class="text">Alındı Belgeleri</i>
            <p class="text">Dernek gelirlerinin tahsilinde kullanılacak “Alındı Belgeleri” yönetim kurulu kararıyla,
                matbaaya bastırılır. </p>
            <p class="text">Alındı belgelerinin bastırılması ve kontrolü, matbaadan teslim alınması, deftere
                kaydedilmesi, eski ve yeni saymanlar arasında devir teslimi ve alındı belgesi ile dernek adına gelir
                tahsil edecek kişi veya kişiler tarafından bu alındı belgelerinin kullanımına ve toplanılan gelirlerin
                teslimine ilişkin hususlarda Dernekler Yönetmeliğinin ilgili hükümlerine göre hareket edilir.</p>
            <i class="text">Yetki Belgesi</i>
            <p class="text">Yönetim kurulu asıl üyeleri hariç, dernek adına gelir tahsil edecek kişi veya kişiler, yetki
                süresi de belirtilmek suretiyle, yönetim kurulu kararı ile tespit edilir. Gelir tahsil edecek kişilerin
                açık kimliği, imzası ve fotoğraflarını ihtiva eden “Yetki Belgesi” dernek tarafından iki nüsha olarak
                düzenlenerek, dernek yönetim kurulu başkanınca onaylanır. Yönetim kurulu asıl üyeleri yetki belgesi
                olmadan gelir tahsil edebilir.</p>
            <p class="text">Yetki belgelerinin süresi yönetim kurulu tarafından en çok bir yıl olarak belirlenir. Süresi
                biten yetki belgeleri birinci fıkraya göre yenilenir. Yetki belgesinin süresinin bitmesi veya adına
                yetki belgesi düzenlenen kişinin görevinden ayrılması, ölümü, işine veya görevine son verilmesi gibi
                hallerde, verilmiş olan yetki belgelerinin dernek yönetim kuruluna bir hafta içinde teslimi zorunludur.
                Ayrıca, gelir toplama yetkisi yönetim kurulu kararı ile her zaman iptal edilebilir.”</p>

            <i class="text">Gelir ve Gider Belgelerinin Saklama Süresi;</i>
            <p class="text">Defterler hariç olmak üzere, dernek tarafından kullanılan alındı belgeleri, harcama
                belgeleri ve diğer belgeler özel kanunlarda belirtilen süreler saklı kalmak üzere, kaydedildikleri
                defterlerdeki sayı ve tarih düzenine uygun olarak 5 yıl süreyle saklanır. </p>
        </div>





        <div class="content">
            <h5 class="header">
                <span class="madde">Beyanname Verilmesi*: </span>
                <br><br> - Madde 15
            </h5>
            <p class="text">
                Derneğin, bir önceki yıla ait faaliyetleri ile gelir ve gider işlemlerinin yıl sonu itibarıyla
                sonuçlarına ilişkin “Dernek Beyannamesi” dernek yönetim kurulu tarafından onaylandıktan sonra, her
                takvim yılının ilk dört ayı içinde dernek başkanı tarafından ilgili mülki idare amirliğine verilir.
            </p>
        </div>


        <div class="content">
            <h5 class="header">
                <span class="madde">Bildirim Yükümlülüğü*: </span>
                <br><br> - Madde 16
            </h5>
            <p class="text">
                Mülki amirliğe yapılacak bildirimler;
            </p>
            <i class="text">Genel Kurul Sonuç Bildirimi</i>
            <p class="text">Olağan veya olağanüstü genel kurul toplantılarını izleyen otuz gün içinde, yönetim ve
                denetim kurulları ile diğer organlara seçilen asıl ve yedek üyeleri içeren (Dernekler Yönetmeliği
                Ek-3’te yer alan) Genel Kurul Sonuç Bildirimi mülki idare amirliğine verilir. Genel kurul toplantısında
                tüzük değişikliği yapılması halinde; genel kurul toplantı tutanağı, tüzüğün değişen maddelerinin eski ve
                yeni şekli, her sayfası yönetim kurulu üyelerinin salt çoğunluğunca imzalanmış dernek tüzüğünün son
                şekli, bu fıkrada belirtilen süre içinde ve bir yazı ekinde mülki idare amirliğine verilir </p>
            <i class="text">Taşınmazların Bildirilmesi</i>

            <p class="text">Derneğin edindiği taşınmazlar tapuya tescilinden itibaren otuz gün içinde (Dernekler
                Yönetmeliği EK-26’da sunulan) “Taşınmaz Mal Bildirimi”ni doldurmak suretiyle mülki idare amirliğine
                bildirilir. </p>

            <i class="text">Yurtdışından Yardım Alma Bildirimi</i>


            <p class="text">Dernek tarafından, yurtdışından yardım alınacak olması durumunda yardım alınmadan önce
                (Dernekler Yönetmeliği EK-4’te belirtilen) “Yurtdışından Yardım Alma Bildirimi” doldurup mülki idare
                amirliğine bildirimde bulunulur.</p>

            <p class="text">Nakdi yardımların bankalar aracılığıyla alınması ve kullanılmadan önce bildirim şartının
                yerine getirilmesi zorunludur.</p>

            <i class="text">Değişikliklerin Bildirilmesi</i>

            <p class="text">Derneğin yerleşim yerinde meydana gelen değişiklik (Dernekler Yönetmeliği EK-24’te
                belirtilen) “Yerleşim Yeri Değişiklik Bildirimi”; genel kurul toplantısı dışında dernek organlarında
                meydana gelen değişiklikler (Dernekler Yönetmeliği EK-25’te belirtilen) “Dernek Organlarındaki
                Değişiklik Bildirimi” doldurulmak suretiyle, değişikliği izleyen otuz gün içinde mülki idare amirliğine
                bildirilir. </p>

            <p class="text">Dernek tüzüğünde yapılan değişiklikler de tüzük değişikliğinin yapıldığı genel kurul
                toplantısını izleyen otuz gün içinde, genel kurul sonuç bildirimi ekinde mülki idare amirliğine
                bildirilir.</p>



        </div>

        <div class="content">
            <h5 class="header">
                <span class="madde">Derneğin İç Denetimi </span>
                <br><br> - Madde 17
            </h5>
            <p class="text">Dernekte genel kurul, yönetim kurulu veya denetim kurulu tarafından iç denetim
                yapılabileceği gibi, bağımsız denetim kuruluşlarına da denetim yaptırılabilir. Genel kurul, yönetim
                kurulu veya bağımsız denetim kuruluşlarınca denetim yapılmış olması, denetim kurulunun yükümlülüğünü
                ortadan kaldırmaz. </p>

            <p class="text">Denetim kurulu tarafından en geç yılda bir defa derneğin denetimi gerçekleştirilir. Genel
                kurul veya yönetim kurulu, gerek görülen hallerde denetim yapabilir veya bağımsız denetim kuruluşlarına
                denetim yaptırabilir.</p>
        </div>



        <div class="content">
            <h5 class="header">
                <span class="madde">Derneğin Borçlanma Usulleri </span>
                <br><br> - Madde 18
            </h5>
            <p class="text">
                Dernek amacını gerçekleştirmek ve faaliyetlerini yürütebilmek için ihtiyaç duyulması halinde yönetim
                kurulu kararı ile borçlanma yapabilir. Bu borçlanma kredili mal ve hizmet alımı konularında olabileceği
                gibi nakit olarak da yapılabilir. Ancak bu borçlanma, derneğin gelir kaynakları ile karşılanamayacak
                miktarlarda ve derneği ödeme güçlüğüne düşürecek nitelikte yapılamaz.
            </p>


        </div>

        <div class="content">
            <h5 class="header">
                <span class="madde">Derneğin Şubelerinin Kuruluşu </span>
                <br><br> - Madde 19
            </h5>
            <p class="text">
                Dernek, gerekli görülen yerlerde genel kurul kararıyla şube açabilir. Bu amaçla dernek yönetim kurulunca
                yetki verilen en az üç kişilik kurucular kurulu, Dernekler Yönetmeliği’nde belirtilen şube kuruluş
                bildirimini ve gerekli belgeleri, şube açılacak yerin en büyük mülki amirliğine verir.
            </p>
        </div>

        <div class="content">
            <h5 class="header">
                <span class="madde">Şubelerin Görev ve Yetkileri </span>
                <br><br> - Madde 20
            </h5>
            <p class="text">
                Şubeler, tüzel kişiliği olamayan, dernek amaç ve hizmet konuları doğrultusunda özerk faaliyetlerde
                bulunmakla görev ve yetkili, tüm işlemlerinden doğan alacak ve borçlarından ötürü kendisinin sorumlu
                olduğu dernek iç örgütüdür.
            </p>
        </div>

        <div class="content">
            <h5 class="header">
                <span class="madde">Şubelerin Organları ve Şubelere Uygulanacak Hükümler </span>
                <br><br> - Madde 21
            </h5>
            <p class="text">
                Şubenin organları, genel kurul, yönetim kurulu ve denetim kuruludur.
                Genel kurul, şubenin kayıtlı üyelerinden oluşur. Yönetim kurulu, beş asıl ve beş yedek, denetim kurulu
                ise üç asıl ve üç yedek üye olarak şube genel kurulunca seçilir.
            </p>
            <p class="text">Bu organların görev ve yetkileri ile bu tüzükte yer alan dernekle ilgili diğer hükümler,
                mevzuatın öngördüğü çerçevede şubede de uygulanır.</p>



        </div>





        <div class="content">
            <h5 class="header">
                <span class="madde">Şubelerin Genel Kurullarının Toplanma Zamanı ve Genel Merkez Genel Kurulunda Nasıl
                    Temsil Edileceği </span>
                <br><br> - Madde 22
            </h5>
            <p class="text">
                Şubeler, genel kurul olağan toplantılarını genel merkez genel kurulu toplantısından en az iki ay önce
                bitirmek zorundadırlar.
            </p>
            <p class="text"> Şubelerin olağan genel kurulu, 3 yılda bir, Eylül ayı içerisinde, şube yönetim kurulunca
                belirlenecek gün yer ve saatte toplanır. </p>
            <p class="text">Şubeler, genel kurul sonuç bildiriminin bir örneğini toplantının yapıldığı tarihi izleyen
                otuz gün içinde mülki idare amirliğine ve dernek genel merkezine bildirmek zorundadırlar.</p>
            <p class="text">Şubeler, şube sayısı üçe kadar genel merkez genel kurulunda tüm üyelerin doğrudan katılımı
                ile; şube sayısı üçten fazla olması durumunda ise, şubede kayıtlı her yirmi (20) üye için bir (1), arta
                kalan üye sayısı 10’dan fazla ise bu üyeler için de bir olmak üzere şube genel kurulunda seçilecek
                delegeler aracılığı ile genel merkez genel kuruluna katılma hakkına sahiptir. </p>

            <p class="text">Genel merkez genel kuruluna en son şube genel kurulunda seçilen delegeler katılır. Genel
                merkez yönetim ve denetim kurulu üyeleri genel merkez genel kuruluna katılır, ancak şube adına delege
                seçilmedikleri sürece oy kullanamazlar. </p>
            <p class="text">Şubelerin yönetim veya denetim kurulunda görevli olanlar genel merkez yönetim veya denetim
                kuruluna seçildiklerinde şubedeki görevinden ayrılırlar.</p>



        </div>


        <div class="content">
            <h5 class="header">
                <span class="madde">Temsilcilik Açma*: </span>
                <br><br> - Madde 23
            </h5>
            <p class="text">
                Dernek, gerekli gördüğü yerlerde dernek faaliyetlerini yürütmek amacıyla yönetim kurulu kararıyla
                temsilcilik açabilir. Temsilciliğin adresi, yönetim kurulu kararıyla temsilci olarak görevlendirilen
                kişi veya kişiler tarafından o yerin mülkî idare amirliğine yazılı olarak bildirilir. Temsilcilik,
                dernek genel kurulunda temsil edilmez. Şubeler temsilcilik açamazlar.
            </p>


        </div>


        <div class="content">
            <h5 class="header">
                <span class="madde">Tüzüğün Ne Şekilde Değiştirileceği </span>
                <br><br> - Madde 24
            </h5>
            <p class="text">
                Tüzük değişikliği genel kurul kararı ile yapılabilir.
            </p>

            <p class="text">Genel kurulda tüzük değişikliği yapılabilmesi için genel kurula katılma ve oy kullanma hakkı
                bulunan üyelerin 2/3 çoğunluğu aranır. Çoğunluğun sağlanamaması sebebiyle toplantının ertelenmesi
                durumunda ikinci toplantıda çoğunluk aranmaz. Ancak, bu toplantıya katılan üye sayısı, yönetim ve
                denetim kurulları üye tam sayısının iki katından az olamaz.</p>
            <p class="text">Tüzük değişikliği için gerekli olan karar çoğunluğu toplantıya katılan ve oy kullanma hakkı
                bulunan üyelerin oylarının 2/3’ü’dür. Genel kurulda tüzük değişikliği oylaması açık olarak yapılır. </p>




        </div>


        <div class="content">
            <h5 class="header">
                <span class="madde"> Derneğin Feshi ve Mal Varlığının Tasfiye Şekli </span>
                <br><br> - Madde 25
            </h5>
            <p class="text">
                Genel kurul, her zaman derneğin feshine karar verebilir.
            </p>

            <p class="text">Genel kurulda fesih konusunun görüşülebilmesi için genel kurula katılma ve oy kullanma hakkı
                bulunan üyelerin 2/3 çoğunluğu aranır. Çoğunluğun sağlanamaması sebebiyle toplantının ertelenmesi
                durumunda ikinci toplantıda çoğunluk aranmaz. Ancak, bu toplantıya katılan üye sayısı, yönetim ve
                denetim kurulları üye tam sayısının iki katından az olamaz.</p>
            <p class="text">Fesih kararının alınabilmesi için gerekli olan karar çoğunluğu toplantıya katılan ve oy
                kullanma hakkı bulunan üyelerin oylarının 2/3’ü’dür. Genel kurulda fesih kararı oylaması açık olarak
                yapılır. </p>

            <i class="text">Tasfiye İşlemleri</i>

            <p class="text">Genel kurulca fesih kararı verildiğinde, derneğin para, mal ve haklarının tasfiyesi son
                yönetim kurulu üyelerinden oluşan tasfiye kurulunca yapılır. Bu işlemlere, feshe ilişkin genel kurul
                kararının alındığı veya kendiliğinden sona erme halinin kesinleştiği tarihten itibaren başlanır. Tasfiye
                süresi içinde bütün işlemlerde dernek adında ibaresi kullanılır. </p>
            <p class="text">Tasfiye kurulu, mevzuata uygun olarak derneğin para, mal ve haklarının tasfiyesi işlemlerini
                baştan sonuna kadar tamamlamakla görevli ve yetkilidir. Bu kurul, önce derneğin hesaplarını inceler.
                İnceleme esnasında derneğe ait defterler, alındı belgeleri, harcama belgeleri, tapu ve banka kayıtları
                ile diğer belgelerinin tespiti yapılarak varlık ve yükümlülükleri bir tutanağa bağlanır. Tasfiye
                işlemeleri sırasında derneğin alacaklılarına çağrıda bulunulur ve varsa malları paraya çevrilerek
                alacaklılara ödenir. Derneğin alacaklı olması durumunda alacaklar tahsil edilir. Alacakların tahsil
                edilmesi ve borçların ödenmesinden sonra kalan tüm para, mal ve hakları, genel kurulda belirlenen yere
                devredilir. Genel kurulda, devredilecek yer belirlenmemişse derneğin bulunduğu ildeki amacına en yakın
                ve feshedildiği tarihte en fazla üyeye sahip derneğe devredilir.</p>

            <p class="text">Tasfiyeye ilişkin tüm işlemler tasfiye tutanağında gösterilir ve tasfiye işlemleri, mülki
                idare amirliklerince haklı bir nedene dayanılarak verilen ek süreler hariç üç ay içinde tamamlanır.</p>


            <p class="text">Derneğin para, mal ve haklarının tasfiye ve intikal işlemlerinin tamamlanmasını müteakip
                tasfiye kurulu tarafından durumun yedi gün içinde bir yazı ile dernek merkezinin bulunduğu yerin mülki
                idare amirliğine bildirilmesi ve bu yazıya tasfiye tutanağının da eklenmesi zorunludur. </p>


            <p class="text">Derneğin defter ve belgelerini tasfiye kurulu sıfatıyla son yönetim kurulu üyeleri
                saklamakla görevlidir. Bu görev, bir yönetim kurulu üyesine de verilebilir. Bu defter ve belgelerin
                saklanma süresi beş yıldır. </p>
        </div>


        <div class="content">
            <h5 class="header">
                <span class="madde">Hüküm Eksikliği </span>
                <br><br> - Madde 26
            </h5>
            <p class="text">
                Bu tüzükte belirtilmemiş hususlarda Dernekler Kanunu, Türk Medeni Kanunu ve bu kanunlara atfen
                çıkartılmış olan Dernekler Yönetmeliği ve ilgili diğer mevzuatın dernekler hakkındaki hükümleri
                uygulanır.
            </p>
        </div>

        <div class="content">
            <h5 class="header">

                <br><br> - Geçici Madde 1.*
            </h5>
            <p class="text">
                İlk genel kurulda dernek organları oluşturulana kadar, derneği temsil edecek ve dernekle ilgili iş ve
                işlemleri yürütecek olan geçici yönetim kurulu üyeleri aşağıda belirtilmiştir.
            </p>

            <p class="text"><strong>Geçici Yönetim Kurulu Üyelerinin;</strong></p>

            <table class="table table-bordered col-md-9">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Adı ve Soyadı</th>
                        <th scope="col">Görev Ünvanı</th>
                    </tr>
                </thead>
                <tbody>

                    <tr>
                        <th scope="row">1</th>
                        <td>Metin Toprak</td>
                        <td>Yönetim Kurulu Başkanı</td>
                    </tr>

                    <tr>
                        <th scope="row">2</th>
                        <td>Atila Bayramoğlu</td>
                        <td>Yönetim Kurulu Başkan Yardımcısı</td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td>Taha Eğri</td>
                        <td>Yönetim Kurulu Başkan Yardımcısı</td>
                    </tr>
                    <tr>
                        <th scope="row">4</th>
                        <td>Necati Balcı</td>
                        <td>Sayman</td>
                    </tr>
                    <tr>
                        <th scope="row">5</th>
                        <td>Özgür Toprak</td>
                        <td>Sekreter</td>
                    </tr>
                    <tr>
                        <th scope="row">6</th>
                        <td>Bayram Karacan</td>
                        <td>Üye</td>
                    </tr>
                    <tr>
                        <th scope="row">7</th>
                        <td>Yüksel Bayraktar</td>
                        <td>Üye</td>
                    </tr>
                </tbody>
            </table>
            <br>
            <p class="text"><strong>Bu tüzük 26 (Yirmialtı) madde ve 1(Bir) geçici maddeden ibarettir.</strong></p>
        </div>

        <div class="content">
            <strong> Not:(*) </strong>
            <p class="text">
                Yıldız ile işaretli madde veya bölümler tüzükte bulunması zorunlu hususlardan değildir. Bu tüzükte yer
                alan kırmızı renkle ve altı çizili yazılanlar Mevzuata aykırı olmamak şartıyla değiştirilebilecek
                hususlardır.
            </p>
        </div>


        <hr>
        <div id="tuzuk-logo">
           <div class="row">
               <div class="col-md-6"> <img src="../../../assets/Img/Logo-tr.jpg" alt=""></div>
               <div class="col-md-6"> <img src="../../../assets/Img/Logo-en.jpg" alt=""></div>
           </div>
        </div>
    </div>
</section>

<script>
    if ((window).scrollTop() > 300) {
        document.getElementById('fast-navi-box').style.visibility = "hidden";
        document.getElementById('fast-navi-box').style.color = "red";

    } else {

        document.getElementById('fast-navi-box').style.visibility = "visible";
    }
</script>

<script type="text/javascript" src="../../../assets/Js/navi-scroll.js"></script>