import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soylesi',
  templateUrl: './soylesi.component.html',
  styleUrls: ['./soylesi.component.css']
})
export class SoylesiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
