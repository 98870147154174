<div id="page-top">

    <div class="content">
        <div class="container">

            <div id="header-text">
                <h1>İletişim</h1>
            </div>
        </div>

    </div>
 
</div>



<section id="contact">
    <div class="container">
        <div class="row top-contact">
            <div class="col-md-12">
                <div class="col-md-5 item">
                    <div class="info-box">
                        <div class="icon-box">
                            <img src="../../../assets/Icons/map-regular-36.png" alt="">

                        </div>
                        <div class="header ">
                            <h4>Adres</h4>
                        </div>
                        <div class="body">
                            <p>
                                Maltepe Mah. Eski Çırpıcı Yolu Sk. Parima No: 8 İç Kapı No: 10 PK: 34010 Zeytinburnu - İSTANBUL
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 item">
                    <div class="info-box ">
                        <div class="icon-box ">
                            <img src="../../../assets/Icons/envelope-regular-36.png" alt="">
                        </div>
                        <div class="header ">
                            <h4>Eposta</h4>
                        </div>
                        <div class="body ">
                            <p>
                                info@istanbulsosyalgirisim.org
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3  item">
                    <div class="info-box ">
                        <div class="icon-box ">
                            <img src="../../../assets/Icons/phone-call-regular-36.png" alt="">
                        </div>
                        <div class="header ">
                            <h4>Telefon</h4>
                        </div>
                        <div class="body ">
                            <p>
                                +90 0212 890 00 18
                                <br> +90 0212 8900035
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <br>
        <div class="row" id="bottom-contact">
            <div class="header">
                <h2>Bizimle İletişime Geçin</h2>
            </div>
            <div class="col-md-12">


                <form id="contact-form" [formGroup]="sendMessageForm" role="form" method="POST">
 
                    <div class="row">
                        <div class="col-md-4">
                            <label for="firstName">
                                    <input  type="text" id="firstName"  formControlName="firstName" placeholder="Adınız *" value="" required="">
                             </label>
                        </div>
                        <div class="col-md-4">
                            <label for="lastName">
                                    <input type="tel" id="lastName" formControlName="lastName"  placeholder="Soyadınız *" value="" required="">
                                </label>
                        </div>
                        <div class="col-md-4">
                            <label for="email">
                                    <input type="email" id="email" formControlName="email"  placeholder="E-posta *" value="" required="">
                                </label>
                        </div>
                        <div class="col-md-12">
                            <label for="message">
                                    <textarea  id="message" formControlName="message" placeholder="Mesajınız *" value="" required=""></textarea>
                                </label>
                        </div>
                    </div>

                    <div class="button-container">
                        <button type="submit" (click)="sendMessage()" title="Gönder">Gönder</button>
                    </div>
                </form>
                
            </div>
        </div>

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96318.07451884531!2d28.70684802490094!3d41.0265723506272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cabb01a44cd997%3A0x6264aa230e88ea30!2sParima%20Ofis!5e0!3m2!1str!2str!4v1620688489209!5m2!1str!2str"
            width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy">
        </iframe>

    </div>

</section>
<br><br><br>