import { SolutionPartner } from './../models/SolutionPartner';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListResponseModule } from '../models/listResponseModule';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../models/globalConstants';

@Injectable({
  providedIn: 'root',
})
export class SolutionPartnerService {
 
  apiUrl:string = GlobalConstants.apiUrl;    

  constructor(private httpClient: HttpClient) {}



  getSolutionPartner():Observable<ListResponseModule<SolutionPartner>> {
    let newPath=this.apiUrl+"SolutionPartners/getall";
   return this.httpClient.get<ListResponseModule<SolutionPartner>>(newPath);
  }
}
