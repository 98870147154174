import { OurPredictionService } from './../../services/our-prediction.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OurPrediction } from 'src/app/models/ourPrediction';

@Component({
  selector: 'app-our-prediction',
  templateUrl: './our-prediction.component.html',
  styleUrls: ['./our-prediction.component.css']
})
export class OurPredictionComponent implements OnInit {

  ourPredictions: OurPrediction[]; 
  dataLoaded: boolean = false;
  constructor(
    private titleService: Title,
    private ourPredictionService: OurPredictionService
  ) { }
  
  ngOnInit(): void {
    this.titleService.setTitle('İSG | Öngörümüz');
    this.getOurPredictions();
  }


  getOurPredictions(){
    this.ourPredictionService.getOurPredictions().subscribe((response) => {
      this.ourPredictions = response.data;
      this.dataLoaded = true;
    });
  }
}
