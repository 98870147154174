<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>

                <span id="slash">/</span>
                <span id="page-now">Yönetim Kurulu</span>
            </div>
            <div id="header-text">
                <h1>Yönetim Kurulu</h1>
            </div>
        </div>

    </div>

</div>

<div class="loader-page" *ngIf="dataLoaded==false">
     
    <span class="loader"><span class="loader-inner"></span></span> &nbsp; <h4> Lütfen Bekleyiniz...</h4>
</div>

<div id="team"  *ngIf="dataLoaded">
    <div class="header">
        <h1>ÜYELER</h1>
    </div>
    <div class="container">



        <div class="team-card" *ngFor="let boardOfDirector of boardOfDirectors">
            <img src="{{boardOfDirector.imgUrl}}" alt="RESİM YOK" class="member-img">
            <div class="member-header">
                <h4>{{boardOfDirector.firstName}} {{boardOfDirector.lastName}}</h4>
            </div>
            <div class="status">
                <h6 class="status-text">{{boardOfDirector.statuName}}</h6>
            </div>
            <div class="member-body">
                <p class="body-text">
                    {{boardOfDirector.explanation}}
                </p>
              

                    <div class="button-container">
                        <a *ngIf="boardOfDirector.facebookUrl" href="{{boardOfDirector.facebookUrl}} " target="_blank" class=" btn-neutral btn-icon facebook btn-round btn-lg">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a *ngIf="boardOfDirector.twitterUrl" href="{{boardOfDirector.twitterUrl}}" target="_blank" class=" btn-neutral btn-icon twitter btn-round btn-lg">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a *ngIf="boardOfDirector.instagramUrl" href="{{boardOfDirector.instagramUrl}}" target="_blank" class=" btn-neutral btn-icon instagram btn-round btn-lg">
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a *ngIf="boardOfDirector.linkedinUrl" href="{{boardOfDirector.linkedinUrl}}" target="_blank" class=" btn-neutral btn-icon linkedin btn-round btn-lg">
                            <i class="fab fa-linkedin"></i>
                        </a>
                    </div>
             
            </div>
        </div>

        

    </div>


</div>