import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arab-image-in-turkis-press',
  templateUrl: './arab-image-in-turkis-press.component.html',
  styleUrls: ['./arab-image-in-turkis-press.component.css']
})
export class ArabImageInTurkisPressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
