import { OurPredictionService } from './../../services/our-prediction.service';
import { OurPointOfViewService } from './../../services/our-point-of-view.service';
import { OurGoalService } from './../../services/our-goal.service';
import { Home } from './../../models/home';
import { HomeService } from './../../services/home.service';
import { SolutionPartnerService } from './../../services/solution-partner.service';
import { MissionVisionService } from './../../services/mission-vision.service';
import { AboutUsService } from './../../services/about-us.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SolutionPartner } from 'src/app/models/SolutionPartner';
import { OurGoal } from 'src/app/models/ourGoal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  dataLoaded:boolean=false;
  headerText: string;
  aboutUsText: string;
  //missionText: string;
  //visionText: string;
  //valuesText: string;

  ourGoalHeader: string; 
  ourGoalText: string;

  ourPointOfViewHeader: string;
  ourPointOfViewText: string;

  ourPredictionHeader: string;
  ourPredictionText: string;

  solutionPartners: SolutionPartner[];
  homes: Home[];

  constructor(
    private titleService: Title,
    private aboutUsService: AboutUsService,
    //private missionVisionService: MissionVisionService,
    private solutionPartnerService: SolutionPartnerService,
    private ourGoalService: OurGoalService,
    private ourPointOfViewService: OurPointOfViewService,
    private ourPredictionService: OurPredictionService,
    private homeService: HomeService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('İstanbul Sosyal Girişim');
    this.getAll();
        
 
  }

  getAll() {
    
 
   
    this.aboutUsService.getAboutUs().subscribe((response) => {
      this.headerText = response.data[0].headerText;
      this.aboutUsText = response.data[0].bodyText.slice(0, 475);
      
    });
    
   /* this.missionVisionService.getMissions().subscribe((response) => {
      this.missionText = response.data.missionText.slice(0, 150);
      this.visionText = response.data.visionText.slice(0, 150);
      this.valuesText = response.data.ourValuesText.slice(0, 150);
    });*/

    this.solutionPartnerService.getSolutionPartner().subscribe((response) => {
      this.solutionPartners = response.data;
    });

    this.homeService.getHomes().subscribe((response) => {
      this.homes = response.data;
      this.dataLoaded =true;
    });

    this.ourGoalService.getFirstOfData().subscribe((response) => {
      this.ourGoalHeader = response.data.ourGoalHeader;
      this.ourGoalText = response.data.ourGoalText.slice(0, 150);
  
 
    });

    this.ourPointOfViewService.getFirstOfData().subscribe((response) => {
      this.ourPointOfViewHeader = response.data.ourPointOfViewHeader;
      this.ourPointOfViewText = response.data.ourPointOfViewText.slice(0, 150);
  
    });

    this.ourPredictionService.getFirstOfData().subscribe((response) => {
      this.ourPredictionHeader = response.data.ourPredictionHeader;
      this.ourPredictionText = response.data.ourPredictionText.slice(0, 150);
 
    });
 

 
  }
  
}
