import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../models/globalConstants';
import { Home } from '../models/home';
import { ListResponseModule } from '../models/listResponseModule';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  
 
  apiUrl:string = GlobalConstants.apiUrl;    
  constructor(private httpClient:HttpClient) { }

  
  getHomes():Observable<ListResponseModule<Home>> {
    let newPath=this.apiUrl+"homes/getall";
   return this.httpClient.get<ListResponseModule<Home>>(newPath);
  }
 
}
