<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>
                <span id="slash">/</span>
                <span id="page-now">Araştırmalar</span>
                <span id="slash">/</span>
                <span id="page-now">Sivil Toplumda Afet Hazırlığı</span>

            </div>
            <div id="header-text">
                <h1>Sivil Toplumda Afet Hazırlığı
                </h1>
            </div>
        </div>

    </div>
</div>

<div id="content">
    <div class="card">

        <div class="card-title">Afet ve Acil Durumlarda Devlet, Özel Sektör ve Üçüncü Sektör İşbirliği</div>

        <p>Covid-19 salgını ile birlikte, başta Cumhurbaşkanlığı ve Aile, Çalışma ve Sosyal Hizmetler Bakanlığı olmak üzere Valilikler, Kızılay, Belediyeler, Müftülükler ve STK’lar proaktif tutumlar geliştirmiştir. Buna göre, ihtiyaç sahiplerini belirleyerek ihtiyaçlarını karşılamak, yüksek risk grubunda olanlara yerinde hizmet götürmek, karantina ve sokağa çıkma yasakları nedeniyle aksayan veya tamamen duran tedarik zincirinin sürdürülmesinde işbirliği ve eşgüdüm mekanizmalarını çalıştırmak için hızla kararlar alınmıştır. Bir yandan kamu ve özel sektör firmaları, diğer yandan MEB meslek liseleri, yüz maskesi, yüz siperi, solunum cihazı, solunum cihazı makinesi gibi tıbbi malzemeleri ve gereçleri üretmek için ülke genelinde bir seferberlik başlatmıştır.</p>

        <p>Salgın, afet ve kriz bağlamında, toplumsal hayatın sürdürülmesinde ana aktörler devlet, özel sektör ve üçüncü sektör (sivil toplum kuruluşları) olarak kategorize edildiğinde; verimli, etkili, kuşatıcı ve kapsayıcı tedbirler ve müdahaleler için bu üç ana paydaş arasında güçlü bir işbirliği ve koordinasyonun olması gerektiği gayet açıktır. Dolayısıyla, her üç ana paydaşın ve bunların alt bileşenlerinin sorumluluk, yetki, görev ve kabiliyetlerinin neler olduğuyla ilgili olarak bir organizasyonel ve fonksiyonel mimarinin tasarlanması ve periyodik olarak tatbikat ve egzersizlerinin yapılması, acil müdahale gereği ortaya çıkmadan hazırlıklı olma bakımından gereklidir. Covid-19 gibi salgın hastalıklar ile deprem, yangın ve sel gibi doğal afetlerde ilk yardım, dezavantajlı grupların tespiti, çevre ve doğanın korunması, sokak hayvanlarının susuzluk ve açlıklarının giderilmesi gibi birçok soruna hızlı müdahale ederek en baştan hızlı ve etkin biçimde olumsuz sonuçları azaltmak açısından olağan dışı koşullar oluşmadan müdahale kapasitesini hazır halde tutmak önemlidir.</p>
        
        <p>Türkiye’de, güçlü merkezi yönetim ve liderliği sayesinde, Covid-19 salgını ile ilgili önlemlerin uygulanmasında çarpıcı bir zafiyet görünmemekle birlikte, salgın, afet ve kriz yönetim ve işletim modelinin geliştirilmesi, üçüncü sektörün kapasitesinin artırılması ve gerektiğinde süreçte daha proaktif bir rol alması, bu tür olumsuzluklarla daha etkin mücadele açısından önem arz etmektedir. Kamu kurumları, özel sektör firmaları ve üçüncü sektör kuruluşları arasında sürdürülebilir güçlü bir iş birliği ve koordinasyonun bulunması, gelişmiş ve gelişmekte olan ülkelerin tamamı için benzer risklerle etkin mücadele edebilmek ve toplumun müdahale kapasitesini geliştirmek açısından önemli görülmektedir. Türkiye’de olduğu gibi diğer ülkelerde de afet ve acil durumlar için geliştirilen tedbirlerin büyük bir kısmı, bütüncül bir yaklaşım sergilemekte ve kamu özel işbirliğini öncelemektedir (Jonsen ve Stryker, 1993; İlter, 2009; WEF, 2015; Enjolras, 2018; British Council, 2019; WHO, 2018 ve 2020; Baum, 2020; Park, 2020; Cooney, 2020; SCVO, 2020, SCVS, 2020; Delivorias ve Scholz, 2020; European Council, 2020; PWC, 2020; Dell’Ariccia, 2020; KPMG, 2020, Duplessis, 2020).</p>
        
        <p>
            Bu projenin kapsamı; (i) salgın, afet ve kriz durumları için üçüncü sektör olarak nitelendirilen sivil toplum kuruluşlarının (STK) hemşehri STK’ları özelinde potansiyel ve kapasitesinin tespiti, (ii) üçüncü sektörün, hemşehri STK’ları özelinde sosyal ağlar boyutu itibariyle kendisinden beklenen işlevler çerçevesinde değerlendirilmesi ve (iii) Mevcut mevzuat çerçevesinde sosyal girişim yaklaşımına paralel olarak afet ve acil durumlara müdahale kapasitesinin geliştirilmesi için devlet, özel sektör ve üçüncü sektör arasında organizasyonel ve fonksiyonel mimari itibariyle bir yönetişim modelinin geliştirilmesi ve bu bağlamda mer’i mevzuatın değerlendirmesidir. Hemşehri STK’larının salgın, afet ve acil durumlara müdahale kapasitesinin geliştirilmesinde İstanbul metropolü örnek olarak alınmıştır. İstanbul özelinde, sivil toplum yapılarının bir alt kümesi olan hemşehri STK’larının faaliyet alanları, hedef kitleleri, halihazırdaki faaliyetleri, nesiller arası kültürel aktarım işlevleri ile mali ve insan kaynakları bakımından değerlendirilmesi ve sürdürülebilir sosyal ağlara ilişkin prototip bir modelin geliştirilmesi hedeflenmiştir.
        </p>
        
        <p>
            Bu projenin geliştirilmesinde, normal zamanların yanı sıra özellikle salgın, afet ve kriz dönemlerinde özel sektör dahil sivil toplum bileşeninin toplumsal temel ihtiyaçları devlet kurumları dışında kendi oluşturduğu inisiyatiflerle çözme kabiliyetini geliştirme yaklaşımı başlıca motivasyon olmuştur. İngiltere öncülüğünde geliştirilen “sosyal girişim” yaklaşımı “toplumsal sorunlara ticari çözümler” mottosuyla takdim edilmektedir. Bu yaklaşımın temelinde, ekonomik ve toplumsal sürdürülebilirliği sağlamak ve günümüz koşullarında yetersiz kalan ve sistemik niteliği bulunmayan bağış, yardım, sadaka gibi uygulamaların daha sistematik ve sistemik bir çerçevede sunulmasına olan ihtiyaç yatmaktadır. Devletin başarısız olduğu, özel sektörün başarısız olduğu ve üçüncü sektörün başarısız olduğu durumlar literatürde yoğun olarak yer almakta; devletin ve özel sektörün öncelikleri ve ana ilgi alanlarının dışında kalan toplumsal sorunlara ancak sivil toplum inisiyatifleri (STK’lar) yoluyla etkin ve hızlı çözüm bulunabileceği dile getirilmektedir. 
        </p>
        
        <p>
            Gönüllü sektör, üçüncü sektör, yurttaş sektörü veya sivil toplum kuruluşları olarak nitelenen sektör, devlet ve özel sektör için bir tehdit olmayıp, ancak bunların görece daha az başarılı oldukları veya bunların doğrudan hedef kitleleri ve öncelikleri arasına girmeyen kesimler, sorunlar ve fırsatlar / imkânlar konusunda inisiyatif alan ve toplumsal yapıyı güçlendiren bir faaliyet alanıdır. Üçüncü sektör kapasitesinin geliştirilmesi toplumsal sürdürülebilirlik bakımından üçlü sacayağı metaforu üzerinden kritik önemdedir. Özellikle salgın, afet ve kriz durumlarında güçlü ve özerk STK’ların toplumsal sürdürülebilirliğe önemli katkılar yaptığı dünya örnekleri mevcuttur (Austin & O’Neil, 2018; Stokke, 2017; Irrera, 2011; Strand, 2003; PAHO, 1999) . 

        </p>
        

        
       
    </div>

    <div class="card">

        <div class="card-title">Afet ve Acil Durumlar için Sivil İnisiyatifi Geliştirme </div>
        <p>Kriz, afet, salgın gibi olağandışı durumlarda hemşehri STK’larının yararlı bir işlev görebilmeleri için, olağan koşullar mevcutken kapasitelerini oluşturmaları veya geliştirmeleri gerekmektedir. Mevcut STK’ların, afet ve acil durumlara müdahale çerçevesinde deyim yerindeyse adeta  “bağlam dışı” oldukları söylenebilir. Hemşehri STK’larından beklenen işlevlere ve hedef kitlelere göre mekânın konumu ve büyüklüğü ve mekânda yürütülecek faaliyetlerin çeşit ve boyut itibariyle tasarımı çeşitlilik gösterecektir. Hemşehri STK’larının hedef kitlelerine yönelik faaliyette bulunan kamu, özel ve diğer STK’larla eşgüdüm, işbirliği ve çözüm ortaklığı bağlamındaki ilişkileri ölçek ekonomilerinden yararlanılmasını, etkin kaynak yönetimi ve performansa dayalı ölçülebilir çıktılar bakımından çeşitli metriklere tabi tutulmasını gerektirmektedir.
        </p>
        <p> Günümüzün teknolojik imkânları sayesinde web tabanlı uygulamalarla sosyal girişim yaklaşımının hemşehri STK’larına etkili bir şekilde uyarlanmasının gerekli olduğu söylenebilir. Sosyal girişim yaklaşımının hemşehri STK’larına uyarlanmasında kente iç göçle gelen toplulukların göç veren iller itibariyle dağılımı, hedef kitlelerinin belirlenmesi, faaliyet alanlarının tespiti ve mali, fiziki ve insan kaynağı planlamasının yapılması ilk aşamayı oluşturmaktadır. Kamu, özel ve üçüncü sektör (STK’lar) itibariyle eşgüdüm ve işbirliğinin temin edilmesi, mükerrer tahsis ve harcamaların önüne geçerek kaynak etkinliğini artıracaktır.
        </p>

        <p>
            Hemşehri STK’larını kamu politikalarının telafi edici, destekleyici ve tamamlayıcı bir aracı olarak tasarlama, önemli bir organizasyon ve koordinasyon yükü getirecektir. Kent yaşamında STK’lara aktif roller verme yaklaşımı, sadece kamu veya özel kesim kaynaklarını kullanan olarak değil, projelendirme, planlama, uygulama ve gözden geçirme süreçlerinde de STK’ların aktif rol almasını gerektirmektedir. Dolayısıyla, rol üstlenen STK’lar kamu, özel ve üçüncü sektör üçlüsünde mümkün olduğunca öncü rolü oynayacak ve giderek daha fazla inisiyatif alma ve kaynak geliştirme yetkinliğini geliştiren bir hedefe doğru evrileceklerdir.
        </p>
    </div>

    <div class="card">
        <div class="card-title">Afet ve Acil Durumlarda Sivil Toplum İnisiyatifi</div>

        <p>
            Afet ve acil durumlarda sivil toplumun görevli kamu kurum ve kuruluşlarının yanı sıra aktif rol alması, müdahale kapasitesinin artırılması, etkililiği ve etkin kullanılması bakımından önemlidir. Sivil toplumun örgütlenmesi, toplumsal kaynaklara ilişkin potansiyel ve kapasitenin geliştirilmesi, toplumsal dönüşümün daha sistemli ve sağlıklı şekilde gerçekleşmesi, kentleşme, iç göç, sanayileşme ve ekonomik büyümenin daha kontrollü, öngörülebilir ve kazanımların daha adil paylaşılmasında daha işlevsel olacaktır.
        </p>
        <p>
            İstanbul ili, sivil toplum örgütlenmesi bakımından öne çıkan üç önemli özelliğe sahiptir. Birincisi, gerekçeleri ve motivasyonu ekonomik kalkınma ile siyasi ve güvenlik alanlarında odaklanan yoğun iç göç ve hızlı ve kontrolsüz kentleşmedir. İstanbul metropolünde ikamet eden nüfusun sadece %14,6’sının kütüğünün İstanbul’a ait olması, buradaki mozaiği ve heterojenliği açıkça göstermektedir. İstanbul’a yoğun göç, kentleşme, entegrasyon ve yerel kültürlerin korunması alanlarında sistematik kamu politikalarına ihtiyaç duyulmaktadır. Türkiye’nin bu yönde özel bir politika düzeneğinin olmadığı görülmektedir. Eğitim, sağlık, bankacılık, ulaşım ve metropolde sunulan diğer hizmetler ile metropolde yaşamanın sözkonusu uyum ve entegrasyonu sağlamasının umulduğu söylenebilir. Ne var ki, kent merkezinin veya merkezlerinin dışında kalan büyük bir nüfus kitlesinin gettolaşma söz konusudur. Latin Amerika ve Hint Alt Kıtasındaki ülkelerde de bu olgu yaygındır. İstanbul metropolünde entegrasyon ve uyum politikalarının geliştirilmesi ve uygulanması özellikle etnik ve mezhepsel farklılaşmanın olumsuz etkilerini karşılama bakımından da son derece önemlidir. Bu çerçevede, güvenlik odaklı kamu politikalarının dışında, toplumsal içerikli mekanizma ve araçların geliştirilmesine ihtiyaç olduğu söylenebilir.
        </p>

        <p>İstanbul’da toplum örgütlenmesinin ikinci boyutu, İstanbul’un tarihinden gelen birikimiyle ilgilidir. İstanbul, Osmanlının son döneminde Beyrut ile birlikte Ortadoğu’nun ticaret ve finans merkezi idi. Osmanlı döneminin sona ermesinden 1990’lı yılların başında sosyalist blokun çöküşüne kadar geçen sürede İstanbul söz konusu rolünü kaybetmiştir. Türkiye’nin AB üyeliğine yönelik adımların hızlandığı son 15 yıllık dönemde, İstanbul’un bölgedeki ticaret ve finans merkezi olma tartışmaları yoğun olarak yapılmış ve bu yönde ulusal düzeyde hazırlık çalışmaları başlatılmıştır. İstanbul’un uluslararası bir merkez olması, entegrasyon, kültürel etkileşim, yerel kimlik ve kültürlerin korunması bağlamında ilave politikalara ihtiyaç duyuracaktır.

        </p>

        <p>
            İstanbul’da örgütlü sivil topluma olan ihtiyacın öncelikli olmasının üçüncü boyutu, İstanbul’un deprem kuşağında olmasıdır. İstanbul, Türkiye milli gelirinin yarısına yakınını üretmektedir. Marmara ekosistemi birlikte değerlendirildiğinde, bu bölgenin Türkiye ekonomisinin lokomotifi olduğu görülmektedir. Dolayısıyla, salgın, afet, deprem ve diğer acil durumlarda müdahale kapasitesinin en üst seviyede olmasına ihtiyaç vardır. Bu kapasitenin sırf kamu kaynakları ve imkânlarıyla oluşturulması pek de olası değildir. Bu nedenle, sivil toplum örgütlenmesinin, afet ve acil durumlara müdahalede yeterli kapasiteye sahip olması önemli bir öncelik olmalıdır.
        </p>

        <p>
            Bu proje kapsamında afet ve acil durumlarda sivil toplum katkısının alınmasına yönelik bir model geliştirilmektedir. Bu modelin sağlıklı işlemesi, İstanbul’da yaşayan toplum kesimlerine yönelik kentleşme, uyum, entegrasyon, toplumsal kalkınma ve kaynak illerle iletişim gibi ileri ve geri bağlantıları dikkate alan başka bir modelin de geliştirilmesini ve birlikte sinerji ortaya çıkarmalarını gerektirmektedir. dolayısıyla, iki farklı bağlamda modeller geliştirilmesi sözkonusu olmuştur. 
        </p>

        <p>
            Birinci model, belediyeler ve / veya İçişleri Bakanlığı Sivil Toplumla İlişkiler Genel Müdürlüğünün (STİGM) inisiyatiflerinde ve koordinasyonlarında kentleşme, entegrasyon, uyum, STK kapasitesinin güçlendirilmesi, ileri ve geri bağlantılarıyla iç göçün hazmedilmesi, dezavantajlı gruplara yönelik kalkındırma ve koruma gibi daha ziyade toplumsal içerikli kapsama sahiptir. 
        </p>

        <p>
            İkinci model ise Afet ve Acil Durum Yönetimi Başkanlığının (AFAD) inisiyatif ve koordinasyonunda afet ve acil durumlara yönelik tematik sivil toplum kuruluşlarının kurgulanmasını veya İstanbul’da yaşayan nüfus gruplarında en ücra köşeye erişimi olan hemşehri STK’ları üzerinden, afet ve acil durumlarda müdahale edecek insan kaynağı kapasitesinin geliştirilmesidir.
        </p>

        <p>
            Hâlihazırda, mevzuatta AFAD ve belediyelerin yukarıda bahsedilen modeller bakımından görevleri açık biçimde belirlenmiştir. Belirsiz olan ise uygulamada sivil toplum kuruluşları yoluyla toplumun afet ve acil durumlara ne şekilde ve hangi kapasite ile müdahil olacakları ve belediyelerin entegrasyon, kültürel aktarım ve uyum programlarına ilişkin müfredat, insan kaynağı ve hedef kitledir. Model önerileri bu ihtiyacı gidermeyi amaçlamaktadır. 

        </p>







    </div>
 

<div class="card">
    <a href="../../../../assets/pdfs/04_SAS_ProjeNihaiRaporu_ANARAPOR_20210211.docx" target="_blank" class="btn btn-success">Araştırma Dosyasını Devamını İncele</a>
    
</div>
 
</div>