<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>
                <span id="slash">/</span>
                <span id="page-now">Bilgi Servisleri</span>
                <span id="slash">/</span>
                <span id="page-now">KVKK AYDINLATMA METNİ</span>
            </div>
            <div id="header-text">
                <h1>KVKK AYDINLATMA METNİ</h1>
            </div>
        </div>

    </div>

</div>
<section id="illumination-text">
    <div class="container">


        <div class="content">
            <h1 class="content-header">
                KVKK AYDINLATMA METNİ
            </h1>

            <h4 style="text-align: center;
            align-items: center;">
                İSTANBUL SOSYAL GİRİŞİM DERNEĞİ
                6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA
                AYDINLATMA METNİ
                </h4>
                <hr style="background-color: #000;">
                <br><br>
            <div class="page-body">
                <h3>Aydınlatma Metni Amacı ve Kapsamı</h3>
                <br><br>
                <p>İstanbul Sosyal Girişim Derneği (“dernek” olarak anılacaktır) 6698 sayılı Kişisel Verilerin Korunması
                    Kanunu (“KVKK” veya “Kanun” olarak anılacaktır.) kapsamında, üyelerimize, üye adaylarımıza ait
                    kişisel verilerin korunması için bir takım tedbirler almaktayız. Kişisel Verilerinizi, KVKK ve
                    ilgili yasal mevzuat kapsamında ve “veri sorumlusu” sıfatımızla aşağıda açıklanan sebeplerle ve
                    yöntemlerle işlemekteyiz. </p>
                <p>
                    Kişisel Verilerin İşlenmesi Hakkında Aydınlatma Metni, KVKK’nın 10. Maddesinde yer alan “Veri
                    Sorumlusunun Aydınlatma Yükümlülüğü” başlıklı maddesi uyarınca: İstanbul Sosyal Girişim Derneği
                    üyelerinin veri kimliği, kişisel verilerinizin toplanma yöntemi - hukuki sebebi, bu verilerin hangi
                    amaçla işleneceği, kimlere - hangi amaçla aktarılabileceği, veri işleme süresi ve KVKK’nın 11.
                    Maddesinde sayılan haklarınızın neler olduğu ile ilgili sizi en şeffaf şekilde bilgilendirme
                    amacıyla hazırlanmıştır.
                </p>
                <br>
                <p>Buna göre; </p>

                <p>Aşağıda belirtilen şekillerde elde ettiğimiz kişisel verilerinizin, tarafınız ile derneğe üyelik için
                    ibraz ettiği kişisel veriler çerçevesinde, işlenmelerini gerektiren amaç çerçevesinde ve bu amaç ile
                    bağlantılı, sınırlı ve ölçülü şekilde, tarafımıza bildirdiğiniz veya bildirildiği şekliyle kişisel
                    verilerin doğruluğunu ve en güncel halini koruyarak, kaydedileceğini, depolanacağını, muhafaza
                    edileceğini, yeniden düzenleneceğini, kanunen bu kişisel verileri talep etmeye yetkili olan kurumlar
                    ile paylaşılacağını ve KVKK'nun öngördüğü şartlarda, yurtiçinde ve yurt dışında üçüncü kişilere
                    aktarılacağını, devredileceğini, sınıflandırılabileceğini ve KVKK'nda sayılan sair şekillerde
                    işlenebileceğini bildiririz. </p>

                <br>
                <ol>
                    <li>
                        <span class="ul-li-header">Veri Sorumlusu</span>
                        <br>
                        <br>

                        <strong>Dernek Unvanı:</strong> İstanbul Sosyal Girişim Derneği <br>
                        <strong>Vergi Dairesi:</strong> Davutpaşa <br>
                        <strong>Vergi Numarası:</strong>481 117 2398 <br>
                        <strong>İnternet Adresi:</strong> <a href="https://istanbulsosyalgirisim.org/" target="_blank">      istanbulsosyalgirisim.org</a> <br> 
                        <strong>E-mail:</strong> <a href="mailto:metin.toprak@istanbul.edu.tr">  metin.toprak@istanbul.edu.tr</a> <br>
                        <strong>Telefon Numarası:</strong> 0.212. 890 00 18 <br>
                        <strong> Adres:</strong> Maltepe Mah. Eski Çırpıcı Yolu Sk. Dış No. 8, İç Kapı No: 10. 34010
                        Zeytinburnu,
                        İstanbul, Türkiye <br>
                        <br>


                    </li>
                    <li><span class="ul-li-header">Kişisel Veri Tanımı</span>
                        <br>
                        <br>
                        <p>KVK Kanunu m.3/I(d) çerçevesinde “kişisel veri”, kimliği belirli veya belirlenebilir gerçek
                            kişilere ilişkin her türlü bilgiyi ifade eder. </p>
                        <p>Bu kapsamda kişisel veri; belirli veya belirlenebilir gerçek bir kişiye ilişkin her türlü
                            bilgiyi ifade etmektedir. </p>

                        <p>Ayrıca KVK Kanunu’na göre; kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı,
                            dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf, sendika, vb.
                            üyelikleri, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili
                            verileri ile biyometrik ve genetik verileri, vb. veriler özel nitelikli kişisel verilerdir.

                        </p>
                        <p>Bu kapsamda, anonim bilgiler, anonim hale getirilen bilgiler ve belirli bir kişi ile
                            ilişkilendirilemeyen diğer veriler Derneğimizin bu konudaki Politikası gereği kişisel veri
                            olarak kabul edilmez.</p>

                        <br><br>

                    </li>
                    <li><span class="ul-li-header">İşlenen Kişisel Verileriniz</span>


                        <br><br>
                        <p>
                            Kişisel Verileriniz yasal yükümlülükler gereği veya dernek iş ve işlemlerinde daha uygun bir
                            hizmet verebilmek amacıyla güvenli bir şekilde Kişisel Verilerin Korunması Kanununa uygun
                            bir şekilde işlenmektedir. <br>
                            Bu kapsamda kişisel veri olarak tabloda yer alan veriler toplanmaktadır;
                        </p>

                        <p><strong>İletişim Verisi: </strong> Üyeye ulaşmak için kullanılabilecek veri grubudur
                            (Telefon, adres, e- posta adresi, referans kişi bilgisi).</p>
                        <p><strong>Kimlik Verisi: </strong> Üye kimliğine dair bilgilerin bulunduğu veri grubudur (Ad
                            soyad, Unvan, TCKN, anne adı, baba adı, doğum tarihi, cinsiyet, uyruk verisi,imza).</p>
                        <p><strong>Görsel/İşitsel Veri: </strong> Üyeye ait görsel ve işitsel verilerin bulunduğu veri
                            grubudur (Fotoğraf, ses kaydı, kamera kaydı).</p>


                        <p><strong>Finansal Veri: </strong> Üyenin finansal bilgilerinin bulunduğu veri grubudur (Kredi
                            kartı, Banka Hesap No, İBAN No, Mail Order No vb.)</p>
                        <p><strong>Mesleki Veriler: </strong>Kişinin mesleğine ait bilgilerin bulunduğu veri grubudur
                            (Çalıştığı kurum bilgisi).</p>
                        <p><strong>Eğitim Verisi: </strong> Kişiye ait eğitim verilerinin bulunduğu veri grubudur.</p>

                        <p><strong>Fiziksel MekanVerisi: </strong> Bu veri kategorisi Çalışan ve ziyaretçilerin giriş
                            çıkış kayıt bilgileri, görüntü, kamera, fotoğraf kayıtları gibi veri türlerini ifade
                            etmektedir. </p>
                        <p><strong>Dernek, Vakıf, Sendika Üyeliği: </strong> Dernek Üyeliği, Vakıf Üyeliği vb üyelikler.
                        </p>

                        <br><br>




                    </li>
                    <li><span class="ul-li-header">Kişisel Verilerin İşlenmesi Kavramı</span>


                        <br><br>

                        <p>KVK Kanunu m.3/I(e) çerçevesinde Kişisel verilerin işlenmesi, kişisel verilerin tamamen veya
                            kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla
                            otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi,
                            değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde
                            edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi
                            veriler üzerinde gerçekleştirilen her türlü işlemi ifade etmektedir.</p>


                        <br><br>




                    </li>
                    <li><span class="ul-li-header">Kişisel Verilerinizin Toplanma Yöntemi</span>


                        <br><br>

                        <p>Kişisel verileriniz, otomatik ya da otomatik olmayan yöntemlerle, derneğin bağlı birimleri,
                            internet sitesi, sosyal medya mecraları, çağrı merkezi, mobil uygulamalar, faaliyetlerin
                            yürütülebilmesi için kullanılan yazılımlar, kamera çekimi vasıtası ile benzeri vasıtalarla
                            sözlü, yazılı ya da elektronik olarak toplanabilecektir. Kişisel verileriniz, dernek ile
                            ilişkiniz devam ettiği müddetçe oluşturularak ve güncellenerek işlenebilecek ve hem dijital
                            hem de fiziki ortamda muhafaza altında tutulabilecektir. </p>
                        <p>Kişisel Verileriniz, Derneğe üyelik başvurusu esnasında fiziki olarak veya <a
                                href="http://tmd.org.tr/language/tr/" target="_blank">www.tmd.org.tr</a> adresindeki
                            Dernek web sitesi üzerinden doldurulan başvuru formu ve Dernek Tüzüğü kapsamında Derneğe
                            üyelik ile ilgili fiziki olarak sunulan veya elektronik ortamda aktarılan belgeler
                            vasıtasıyla, ayrıca Dernek ile yapılan çeşitli iletişimler kapsamında (telefon veya
                            elektronik posta ile Dernek ile iletişime geçmeniz gibi) Dernek yetkililerine verilen
                            bilgiler vasıtasıyla sözlü veya yazılı olarak veya elektronik ortamlarda toplanmakta ve
                            işlenmektedir.</p>
                        <br><br>




                    </li>
                    <li><span class="ul-li-header">Kişisel Verilerinizin İşlenme Amaçları</span>

                        <br><br>

                        <p>5.1. Dernek Tüzüğünde belirtilen amaçlar ve çalışma konuları doğrultusunda faaliyetlerin
                            yürütülmesi,</p>
                        <p>5.2. Derneğin genel kurul toplantıları, çalışma grubu toplantıları, komisyon toplantıları,
                            zirveler, gündem toplantıları, yurt içinde veya yurt dışında yapılacak çalışmalar, diğer üye
                            toplantıları gibi Dernek üyeleri veya temsilcilerine yönelik etkinliklerin ve ilgili
                            süreçlerin organizasyonu, yönetilmesi ve yürütülmesi, </p>
                        <p>5.3. Dernek ile üyeler arasında üyelik hakları ve yükümlülüklerine ilişkin konularda
                            iletişimin sağlanması ve ilgili hak ve yükümlülüklerin yerine getirilmesi, </p>
                        <p>5.4. Derneğin tanıtımı ve gerçekleştirilen etkinlikler hakkında bilgi verilmesi, </p>
                        <p>5.5. Derneğin faaliyet ve hizmetlerinin belirlenmesi, uygulanması, hizmet politikalarının
                            yürütülmesi, </p>

                        <p>5.6. Dernek içi iletişim ve işbirliğinin güçlendirilmesi, </p>

                        <p>5.7. Dernek içi raporlama faaliyetlerinin icrası,</p>

                        <p>5.8. Dernek faaliyetleri kapsamında üyelerin bilgilendirilmesi,</p>

                        <p>5.9. Dernek bünyesinde bağış ve yardımların toplanması ve Dernek tarafından bağış ve
                            yardımlarda bulunulması</p>

                        <p>5.10. Derneğe ait tüm tesislerin güvenlik ve denetiminin yürütülmesinin temini, </p>

                        <p>5.11. İdari kurum ve kuruluşların ve mahkemelerin talebi üzerine gerekli bilgi ve belgelerin
                            bu kurum, kuruluş veya mahkemelere sunulması, </p>

                        <p>5.12. Mevzuat gereği Dernek tarafından tutulması öngörülen defter ve kayıtların tutulması
                        </p>


                        <p>5.13. 5223 sayılı Dernekler Kanunu, Dernekler Yönetmeliği ve diğer sair mevzuat hükümleri
                            uyarınca yükümlülüklerin yerine getirilmesi, </p>

                        <p>5.14. Üyeler ile ilgili hukuki süreçlerin yürütülmesi, </p>

                        <p>5.15. Veri kayıplarının önlenebilmesi için kopyalanması/yedekleme yapılması,</p>

                        <br>
                        <p>Amaçlarıyla, 6698 sayılı Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme
                            şartları ve amaçları dâhilinde işlenecektir. </p>

                        <p>Dernek, Kişisel Verilerinizin işlenmesinde başta Türkiye Cumhuriyeti Anayasası, ülkemizin
                            taraf olduğu uluslararası sözleşmeler, KVKK, ikincil mevzuat ve Kişisel Verileri Koruma
                            Kurulu tarafından yayınlanan rehberler ve ilke kararlar başta olmak üzere ilgili mevzuat
                            kapsamında belirtilen veri işleme ilkelerine ve yükümlülüklerine uygun davranmaktadır. </p>




                        <br><br>




                    </li>
                    <li><span class="ul-li-header">İşlenen Kişisel Verilerinizin Kimlere ve Hangi Amaçla
                            Aktarılabileceği </span>


                        <br><br>
                        <p>Dernek, Kişisel Verilerinizi aşağıda belirtildiği şekil ve durumlarda, KVKK tarafından
                            öngörülen temel ilkelere uygun olarak ve KVKK’nın 8. ve 9. maddelerinde belirtilen şartlara
                            uygun olarak aşağıda belirtilen kişilere aktarabilecektir: </p>

                        <p>Dernek üyeliği kapsamında 5223 sayılı Dernekler Kanunu ve Dernekler Yönetmeliği de dahil
                            olmak üzere kişisel verilerin işlendiği süre boyunca yürürlükte olan herhangi bir mevzuatta
                            öngörülmesi veya mevzuatta öngörülen bir yükümlülüğün yerine getirilebilmesi için bu
                            verilerin paylaşılmasının da gerekli olması durumunda Kişisel Verileriniz Derneğin ilgili
                            kanuni veya hukuki yükümlülüklerin yerine getirebilmesi için ve bununla sınırlı olarak
                            ilgili kurum ve kuruluşlar ile paylaşılacaktır. </p>

                        <p>Dernek üyeliği veya tüzel kişi üyenin gerçek kişi temsilciliğine ek olarak Dernek
                            organlarında görev almanız durumunda Kimlik Bilgileriniz, İletişim Bilgileriniz ve meslek
                            bilginiz Dernekler Kanunu ve ikincil mevzuattaki çeşitli yükümlülüklerin yerine
                            getirilebilmesi amacıyla veri sorumlusu olan Derneğin kanuni ve hukuki yükümlülüğünü yerine
                            getirebilmesi için zorunlu olması hukuki sebepleriyle İl Dernekler Müdürlüğü ve ilgili diğer
                            kamu kurum ve kuruluşları ile paylaşılacaktır. </p>

                        <p>Dernek toplu elektronik posta gönderimine ilişkin olarak üçüncü kişilerden hizmet almakta
                            olup, Dernek üyeliği kapsamında tarafınıza Derneğin faaliyetleri ve gerçekleştirilen
                            etkinlikler hakkında bilgi verecek elektronik postaların gönderilebilmesi için, elektronik
                            posta adresleriniz gönderimin yapılacağı dönemde hizmet alınan ajans ile temel hak ve
                            özgürlüklerinize zarar vermemek kaydıyla, Derneğin meşru menfaati kapsamında
                            paylaşılacaktır. </p>

                        <p>Dernek bünyesinde düzenlenen genel kurul toplantıları, çalışma grubu toplantıları, zirveler,
                            gündem toplantıları, diğer üye toplantıları gibi çeşitli faaliyet ve hizmetlerin sağlanması
                            amacıyla Dernek dışı organizasyon firmaları ile çalışılıyor olup, etkinliklerin
                            organizasyonunu sağlamak ve katılımcılara etkinliğe ait kimlik kartları gibi materyalleri
                            sağlamak amacıyla söz konusu etkinliğe katılacak kişilerin isim ve soy isim bilgileri
                            Derneğin belirli etkinlikleri açısından yetkilendirdiği ve o iş özelinde veri işleyen
                            sıfatını taşıyacak olan organizasyon firması ile temel hak ve özgürlüklerinize zarar
                            vermemek kaydıyla veri sorumlusu olan Derneğin meşru menfaati kapsamında paylaşılacaktır.
                        </p>


                        <p>İlgili firmalara hususi olarak bir veri aktarımı yapılmamakla birlikte, Dernek bünyesinde
                            bilgi teknolojileri hizmetlerinin sağlanması amacıyla Dernek dışı bilgi teknolojileri
                            firmaları ile çalışılmakta olup, üyelerimizin (veya tüzel kişi üyelerimizin gerçek kişi
                            temsilcilerinin) temel hak ve özgürlüklerine zarar vermemek kaydıyla, Derneğin meşru
                            menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebine dayalı olarak, bu
                            hizmetlerin görülmesi kapsamında bu firmalar, teknik destek sağlamak amacı ile zaman zaman
                            Derneğin kullandığı sunucu ve programlara, bu kapsamda dolaylı olarak da bu programlar
                            altında olan verilere erişebilmektedir. Ancak bu firmalar tarafından verilerin kendi
                            sistemlerine aktarılması veya kendileri tarafından da bu verilerin işlenmesi ve saklanması
                            söz konusu olmamaktadır. </p>

                        <p>Üyenin hak veya yükümlülükleri veya Dernek nezdindeki üyelik ile ilgili herhangi bir
                            uyuşmazlık olması halinde, ilgili uyuşmazlık ile ilgili olduğu ölçüde ve sınırlı olarak
                            Kişisel Verileriniz Derneğin avukatları, danışmanları ve ilgili yargı makamları ve icra
                            mercileri ile veri sorumlusu Derneğin haklarının tesisi, kullanılması veya korunması için
                            zorunlu olması kapsamında paylaşılabilecektir.</p>

                        <p>
                            Derneğin yukarıda belirtilen veri işleme amaçları ve Dernek Tüzüğü kapsamındaki
                            faaliyetlerini yerine getirebilmesi için gerekli olması durumunda, yalnızca bunlarla sınırlı
                            olarak, Derneğin hizmet, destek ve danışmanlık aldığı danışman, mali müşavir, denetçi gibi
                            yurtiçinde yerleşik üçüncü kişiler ile Derneğin haklarının tesisi, kullanılması veya
                            korunması için zorunlu olması ve temel hak ve özgürlüklerinize zarar vermemek kaydıyla veri
                            sorumlusu olan Derneğin meşru menfaati kapsamında paylaşılabilecektir.
                        </p>
                        <p>Yukarıda sayılanlara ek olarak, ilgili işlem veya talep tarihinde yürürlükte olan mevzuat
                            tahtındaki yasal ve/veya hukuki yükümlülüklerin yerine getirilmesi için gerekmesi halinde
                            veya resmi kurumlardan bu şekilde bir talep gelmesi durumunda yetkililerce istenecek Kişisel
                            Verileriniz ilgili kurum ve kuruluşları ile veya yargı organları ile kanuni veya hukuki
                            yükümlülüklerin yerine getirilmesi amacıyla paylaşılabilecektir. </p>
                        <br><br>




                    </li>
                    <li><span class="ul-li-header">Kişisel Verilerin Silinmesi, Yok Edilmesi ve Anonim Hale Getirilmesi
                        </span>


                        <br><br>
                        <p>Kişisel verileriniz, işbu Aydınlatma Metninde belirtilen amaçlarla sınırlı olmak üzere;
                            Dernek ve Derneğin bağlı tüm birimleri ile ilgili tüm kanun ve sair yasal mevzuatta yer alan
                            veri işleme ve zamanaşımı sürelerine riayet edilerek işlenecektir. Kanunlarda veri işleme
                            sürelerine ilişkin değişiklik yapılması halinde, belirlenen yeni süreler esas alınacaktır.
                        </p>

                        <p>KVKK Kanunu’nun 7. maddesi uyarınca, kişisel verilerin ilgili mevzuata uygun olarak işlenmiş
                            olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel veriler
                            re’sen veya ilgili kişinin talebi üzerine Dernek tarafından silinir, yok edilir veya anonim
                            hale getirilir. Kişisel verileriniz, ilgili mevzuatta belirtilen veya işleme amacının
                            ortadan kalkmasına kadar geçecek olan makul süre ve herhalde kanuni zamanaşımı süreleri
                            kadar muhafaza edilecektir.</p>
                        <br><br>




                    </li>
                    <li><span class="ul-li-header">Veri İşleme Süresi ve Muhafaza Süresi</span>


                        <br><br>
                        <p>Kişisel verileriniz, işbu Aydınlatma Metninde belirtilen amaçlarla sınırlı olmak üzere;
                            Derneğimiz tarafından ilgili tüm kanun ve sair yasal mevzuatta yer alan veri işleme ve
                            zamanaşımı sürelerine riayet edilerek işlenecektir. Kanunlarda veri işleme sürelerine
                            ilişkin değişiklik yapılması halinde, belirlenen yeni süreler esas alınacaktır.</p>


                        <br><br>




                    </li>
                    <li><span class="ul-li-header">Kişisel Verileri İşlenen Üyenin Hakları </span>


                        <br><br>
                        <p>KVKK' nun 11. maddesi gereği bize kimliğinizi ibraz edip, gerekli kontrollerin yapılmasına
                            müteakip, üyelerimiz kişisel verileri ile ilgili aşağıda yer alan haklara sahiptirler: </p>

                        <br>
                        <ol type="a">
                            <li>
                                <p>Kişisel verilerinin işlenip işlenmediğini öğrenme, </p>
                            </li>
                            <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme, </li>
                            <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
                                öğrenme, </li>
                            <li>Yurt içinde ve yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, </li>
                            <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
                                isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere
                                bildirilmesini isteme,</li>
                            <li>KVKK Kanunu ve ilgili diğer kanun hükümlerine uygun olarak işlenmişolmasına rağmen,
                                işlenmesini gerektiren sebeplerin ortadan kalkması hâlindekişisel verilerin silinmesini
                                veya yok edilmesini isteme ve bu kapsamda yapılanişlemin kişisel verilerin aktarıldığı
                                üçüncü kişilere bildirilmesini isteme,</li>
                            <li>İşlenen verilerin analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucunortaya
                                çıkması halinde bu sonuca itiraz etme,</li>
                            <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramasıhâlinde
                                zararın giderilmesini talep etme.</li>
                        </ol>
                        <br><br>




                    </li>
                </ol>
            </div>
        </div>
    </div>
</section>