import { AboutUsService } from './../../services/about-us.service';
import { Component, OnInit } from '@angular/core';
import { AboutUs } from 'src/app/models/aboutUs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private aboutUsService:AboutUsService) { }

  aboutUsBodyText:string;

  ngOnInit(): void {
    this.getAboutText();
  }

  getAboutText(){

    this.aboutUsService.getAboutUs().subscribe((response)=>{
      this.aboutUsBodyText = response.data[0].bodyText.slice(0,300);
 
    },(errorResponse)=>{

    });

  }

}
