<div id="page-top">

    <div class="content">
        <div class="container">
            <div id="page-location">
                <span id="page-home"><a routerLink=""> Ana Sayfa</a></span>
                <span id="slash">/</span>
                <span id="page-now">Kurumsal</span>
                <span id="slash">/</span>
                <span id="page-now">Bakış Açımız</span>
            </div>
            <div id="header-text">
                <h1>Bakış Açımız</h1>
            </div>
        </div>

    </div>
</div> 


<div class="loader-page" *ngIf="dataLoaded==false">
     
    <span class="loader"><span class="loader-inner"></span></span> &nbsp; <h4> Lütfen Bekleyiniz...</h4>
</div>
 

<div id="our-point-of-view" *ngIf="dataLoaded">
    <div class="container">


        <div class="content" *ngFor="let ourPointOfView of ourPointOfViews">
            <h3 class="header">{{ourPointOfView.ourPointOfViewHeader}}</h3>
            <p class="text">{{ourPointOfView.ourPointOfViewText}}
            </p>
        </div>



    </div>
</div>


